import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonComponent from "../Button";
import Dropdown from "../dropdown/Dropdown";
import { getAPIHandler, putAPIHandler } from "../../config/service";
import toast from "react-hot-toast";

export default function MaxWidthDialog({requestedLeadIds ,disabled , requestedLeads ,onApiSuccess}) {
  const [open, setOpen] = React.useState(false);
  const [executives, setExecutives] = React.useState([]);
  console.log(executives, "executives")
  const [selectedExecutive, setSelectedExecutive] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (selectedOption) => {

    const selectedExec = executives.find(exec => exec.label === selectedOption);
    setSelectedExecutive(selectedExec ? selectedExec.value : "");
  };

  const fetchUserData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "listUserDetailsByType",
      });

      if (
        response.data &&
        response.data.result &&
        response.data.result.userDetails
      ) {
      
        const users = response.data.result.userDetails;
        console.log(users)
        const salesExecutives = users
        .filter(
          (user) =>
            user.userType === "EXECUTIVE" &&
            user.userRoleType === "PRE_SALES"
        )
        .sort((a, b) => a.fullName.localeCompare(b.fullName));
        console.log(salesExecutives, "salesExecutives")
        
        const executiveOptions = salesExecutives.map(
          (executive) => ({
            label: executive.fullName,
            value: executive._id
          })
        );

        setExecutives(executiveOptions);
      } else {
        console.error("No user data received.");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const reassignExecutive = async () => {
    console.log(requestedLeadIds,"requestedLeadIds", selectedExecutive)
    if (!selectedExecutive || !requestedLeadIds || requestedLeadIds.length === 0) {
      console.error("Missing data for reassignment.");
      return;
    }
    if(!requestedLeads){
    try {
      const response = await putAPIHandler({
        endPoint: "reassignExecutiveToMultipleLeads",
        dataToSend: {
          leadIds: requestedLeadIds,
          executiveId: selectedExecutive, 
        },
      });
      if (response && response.data) {
        console.log("Reassigned successfully", response.data);
        toast.success(`Executive re-assigned to ${response.data.updatedLeads} leads.`);
        handleClose();
        onApiSuccess() 
      } else {
        console.error("Failed to reassign.");
        toast.error("Failed to reassign.");
      }
    } catch (error) {
      console.error("Error during reassignment:", error);
    }
  }else{
    try {
      const response = await putAPIHandler({
        endPoint: "reassignPreSaleExecutiveToMultipleLeads",
        dataToSend: {
          leadIds: requestedLeadIds,
          executiveId: selectedExecutive, 
        },
      });
      if (response && response.data) {
        console.log("Reassigned successfully", response.data);
        toast.success(`Executive re-assigned to ${response.data.updatedLeads} leads.`);
        handleClose();
        onApiSuccess() 
      } else {
        console.error("Failed to reassign.");
        toast.error("Failed to reassign.");
      }
    } catch (error) {
      console.error("Error during reassignment:", error);
    }
  }
  };

  React.useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <React.Fragment>
      <ButtonComponent  name={"Re-assign Selected"}  borderRadius={"100px"} onClick={handleClickOpen} disabled={disabled}/>
      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {},
        }}
      >
        <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
          Reassign Executive Member
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <Dropdown
              options={executives.map(exec => exec.label)}
              onSelect={handleSelect}
              width={"289px"}
              border={"none"}
              color={"#ccc"}
            />
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <ButtonComponent
            name={"Assign"}
            onClick={reassignExecutive}
            width={"10rem"}
            borderRadius={"100px"}
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
