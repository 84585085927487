// AuthContext.js
import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const sessionUser = JSON.parse(sessionStorage.getItem("user"));
  const initialAuthState = sessionUser ? true : false;

  const [isAuthenticated, setIsAuthenticated] = useState(initialAuthState);
  const [user, setUser] = useState(sessionUser);

  useEffect(() => {
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    if (sessionUser) {
      setIsAuthenticated(true);
      setUser(sessionUser);
    }
  }, []);

  const login = (userData) => {
    console.log("Logging in with userData:", userData);
    setIsAuthenticated(true);
    setUser(userData);
    sessionStorage.setItem("user", JSON.stringify(userData));
    sessionStorage.setItem("token", userData.token);
    
  };

  const logout = () => {
    console.log("Logging out");
    setIsAuthenticated(false);
    setUser(null);
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("token");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
