import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import MainLayout from "../../../layout/MainLayout";
import { Paper, Grid, Typography, Box } from "@mui/material";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
import ButtonComponent from "../../../component/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import arrowBack from "../../../assets/arrowBack.svg";
import { getAPIHandler, putAPIHandler } from "../../../config/service";
import { broadcastSchema } from "../../../schemas";

const EditBroadcast = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState({});

  const userTypeOptions = [
    { value: "VP", label: "Vice President" },
    { value: "MANAGER", label: "Manager" },
    { value: "EXECUTIVE", label: "Executive" },
  ];

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await putAPIHandler({
        endPoint: "updateBroadcast",
        dataToSend: {
          broadcastId: id,
          title: values.title,
          broadcastMessage: values.broadcastMessage,
          userType: values.userType,
        },
      });

      console.log(response.data);
      navigate("/broadcastmanagement");
    } catch (error) {
      console.error("Error:", error);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "getBroadcastById",
        paramsData: {
          broadcastId: id,
        },
      });

      console.log("Response:", response.data);

      setData(response.data.result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <MainLayout>
      <Box display="flex" flexDirection="column">
        {/* Header */}
        <Box display="flex" alignItems="center" padding="1rem">
          <Box display="flex" alignItems="center" padding="1rem">
            <Typography variant="h4" fontWeight={700}>
              Broadcast Management
            </Typography>
          </Box>
        </Box>

        {/* Form Section */}
        <Box sx={{ marginLeft: "15px", marginBottom: "15px" }}>
          <Paper elevation={0} sx={{ padding: "15px" }}>
            <Box display="flex" alignItems="center" mb={2}>
              <Link to="/broadcastmanagement">
                <img
                  src={arrowBack}
                  alt="arrowBack"
                  style={{
                    width: "2.813rem",
                    height: "2.813rem",
                    cursor: "pointer",
                  }}
                />
              </Link>
              <Typography variant="h5" sx={{ marginLeft: "1rem" , fontWeight: 800}}>
                Edit Broadcast
              </Typography>
            </Box>

            <Formik
              initialValues={{
                title: data ? data.title : "",
                broadcastMessage: data ? data.broadcastMessage : "",
                userType: data ? data.userType : "",
              }}
              enableReinitialize={true}
              validationSchema={broadcastSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, handleBlur, setFieldValue, values }) => (
                <Form>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    maxWidth="500px"
                    mx="auto" // Center the form container horizontally
                  >
                    {/* Title Field */}
                    <Box mb={2} width="100%">
                      <Field
                        as={Input}
                        type="text"
                        name="title"
                        placeholder="Enter Title"
                        fullWidth
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="error"
                      />
                    </Box>

                    {/* Broadcast Message Field */}
                    <Box mb={2} width="100%">
                      <Field
                        as={Textarea}
                        minRows={4}
                        name="broadcastMessage"
                        placeholder="Enter Broadcast Message"
                        fullWidth
                      />
                      <ErrorMessage
                        name="broadcastMessage"
                        component="div"
                        className="error"
                      />
                    </Box>

                    {/* User Type Field */}
                    <Box mb={2} width="100%">
                      <Select
                        name="userType"
                        options={userTypeOptions}
                        value={userTypeOptions.find(
                          (option) => option.value === values.userType
                        )}
                        onChange={(selectedOption) =>
                          setFieldValue(
                            "userType",
                            selectedOption ? selectedOption.value : ""
                          )
                        }
                        onBlur={handleBlur}
                        isClearable
                        placeholder="Select User Type"
                        fullWidth
                      />
                      <ErrorMessage
                        name="userType"
                        component="div"
                        className="error"
                      />
                    </Box>

                    {/* Submit Button */}
                    <Box
                      mb={2}
                      width="100%"
                      display="flex"
                      justifyContent="center"
                    >
                      <ButtonComponent
                        type="submit"
                        name="Update Broadcast"
                        disabled={isSubmitting}
                        height="43px"
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Paper>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default EditBroadcast;
