import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import locationLogo from "../../assets/location.png";
import MovieSlider from "../PropertyDetail/MovieSlider";
import Popup from "../Popup/Popup";
import "./LeadDetailReq.css";
import { useParams } from "react-router-dom";
import { getAPIHandler } from "../../config/service";

const LeadDetailReq = (props) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const statusMap = {
    RESENTREQUEST: "Resent Request",
    GENERATEDLEAD: "Generated Lead",
    BOOKEDVISIT: "Booked Visit"
  };

  const getData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "getLeadsDetails",
        paramsData: {
          generatedLeadId: id,
        },
      });

      console.log("Response:", response.data);
      setData(response.data.result.leadDetails[0]);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  const propertyImages = data.propertyDetails.filesURL.length
  ? data.propertyDetails.filesURL.map((image) => image.url)
  : [
      'https://cdn.pixabay.com/photo/2023/09/17/22/25/witch-8259351_1280.jpg',
      'https://cdn.pixabay.com/photo/2024/03/05/20/48/church-8615302_1280.jpg',
      'https://cdn.pixabay.com/photo/2022/11/04/12/00/golden-hour-7569709_1280.jpg',
    ];

  let additionalData = null;
  if (statusMap[data.leadStatus] === "Booked Visit") {
    additionalData = (
      <div className="additional-data">
        <div>Visit Status: <strong>Visit Done</strong></div>
        <div>Upcoming Visit Date : <strong>12 March, 2022</strong></div>
        <div className="popup">
          <Popup />
        </div>
      </div>
    );
  }

  return (
    <Paper elevation={1} style={{ width: "75vw", borderRadius: "10px" }}>
      <div className="LeadDetailReq-container">
        <div className="LeadDetailReq-header-wrapper">
          <div className="LeadDetailReq-header">
            Property Name : <strong>{data.propertyDetails.propertyName}</strong>
          </div>
          <div className="LeadDetailReq-header">
            <img src={locationLogo} alt="locationImage" />
            {data.propertyDetails.address}
          </div>
        </div>
        <div className="LeadDetailReq-wrapper">
          <div className="LeadDetailReq-content">
            <div className="LeadDetailReq-left">
              <MovieSlider images={propertyImages} />
            </div>
          </div>
          <div className="LeadDetailReq-content">
            <div className="LeadDetailReq-right">
              <div className="data">Client Details:</div>
              <div>
                Name : <strong>{data.clientName}</strong>
              </div>
              <div>
                Phone No: <strong>{data.clientNumber}</strong>
              </div>
              <div>
                Email: <strong>{data.clientEmail}</strong>
              </div>
              <div>
                Request Received On: <strong>{new Date(data.createdAt).toLocaleString('en-IN', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  timeZone: 'Asia/Kolkata',
                })}</strong>
              </div>
              <div>
                Status: <strong>{statusMap[data.leadStatus]}</strong>
              </div>
              {additionalData}
            </div>
          </div>
        </div>
      </div>
      {popupOpen && <Popup/>}
    </Paper>
  );
};

export default LeadDetailReq;
