import React from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";


const Profile = ({ name, type, profileImage }) => {
  // const [anchorEl, setAnchorEl] = useState(null);
  // const [selectedType, setSelectedType] = useState(type);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const truncatedName = name?.length > 25 ? `${name.slice(0, 25)}...` : name;

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const handleMenuItemClick = (selectedRole) => {
  //   setSelectedRole(selectedRole);
  //   handleClose();
  // };

  return (
    <Grid
      container
      alignItems="center"
      style={{
        background: "rgba(0, 176, 80, 0.05)",
        borderRadius: "2rem", // converted from 100px to 2rem
        height: "4rem", // converted from 60px to 4rem
        width: "100%",
        maxWidth: isMobile ? "100%" : "18.625rem", // converted from 298px to 18.625rem
        margin: "0 auto",
        marginBottom: isMobile ? "20px" : 0, // added to match Sidebar layout
        flexShrink: 0 // ensures the Profile component doesn't shrink when zooming
      }}
    >
      <Grid item>
        
      {profileImage ? (
        <img
          src={profileImage}
          alt="Profile"
          style={{
            width: "4rem",
            height: "4rem",
            borderRadius: "50%",
          }}
        />
      ) : (
        <AccountCircleIcon
          style={{
            width: "4rem",
            height: "4rem",
            color: "rgba(0, 0, 0, 0.54)",
          }}
        />
      )}
      </Grid>
      <Grid item sx={{ marginLeft: "1rem" }}>
        <Typography sx={{ fontWeight: 700 }}>{truncatedName}</Typography>
        <Typography sx={{ color: "rgba(0, 0, 0, 0.4)", fontWeight: 500 }}>
          {type}
        </Typography>
      </Grid>
    
    </Grid>
  );
};

export default Profile;

// <Grid item sx={{ marginLeft: "auto", marginRight: "1rem" }}>
// <KeyboardArrowDownRoundedIcon onClick={handleClick} style={{ marginRight: "0.5rem" }} />
// <Menu
//   anchorEl={anchorEl}
//   open={Boolean(anchorEl)}
//   onClose={handleClose}
// >
//   <MenuItem onClick={() => handleMenuItemClick("ADMIN")}>Admin</MenuItem>
//   <MenuItem onClick={() => handleMenuItemClick("VP")}>Vice president</MenuItem>
//   <MenuItem onClick={() => handleMenuItemClick("MANAGER")}>Manager</MenuItem>
//   <MenuItem onClick={() => handleMenuItemClick("EXECUTIVE")}>Executive</MenuItem>
// </Menu>
// </Grid>


