import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const FieldForInput = ({
  type,
  name,
  placeholder,
  color,
  backgroundColor,
  borderRadius,
  width,
  height,
  border,
  onChange,
  onBlur,
  maxLength,
  value,
  restrictCopypaste
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleCopypaste = (e) => {
    if(restrictCopypaste){
      e.preventDefault()
    }
  }

  return (
    <div style={{ position: 'relative', marginBottom: '40px' }}>
      <input
        type={showPassword ? 'text' : type}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onCopy={handleCopypaste}
        onCut={handleCopypaste}
        onPaste={handleCopypaste}
        maxLength={maxLength}
        style={{
          color: color,
          backgroundColor: backgroundColor,
          borderRadius: borderRadius,
          padding: '20px',
          border: border,
          outline: 'none',
          width: width,
          height: height,
          boxSizing: 'border-box',
          fontSize: '14px',
          fontWeight: '700',
        }}
      />
      {type === 'password' && (
        <IconButton
          onClick={handleTogglePassword}
          style={{
            position: 'absolute',
            top: '50%',
            right: '30px',
            transform: 'translateY(-50%)',
            padding: '0px',
          }}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      )}
    </div>
  );
};

FieldForInput.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  borderRadius: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  border: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  maxLength: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};

export default FieldForInput;
