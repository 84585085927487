import React, { useState, useEffect } from "react";
import MainLayout from "../../../layout/MainLayout";
import ViewDetail from "../../../component/viewDetail/ViewDetail";
import {
  Paper,
  Grid,
  Typography,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // MUI Icon for Back Arrow
import { Link, useParams } from "react-router-dom";
import { getAPIHandler } from "../../../config/service";
import arrowBack from "../../../assets/arrowBack.svg";

const ViewProperty = () => {
  const [data, setData] = useState(null);
  const [selectedImage, setSelectedImage] = useState(0);
  const [customImage, setCustomImage] = useState(null);
  const [defaultImages, setDefaultImages] = useState([]);

  const { id } = useParams();

  const getData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "viewPropertyDetails",
        paramsData: {
          propertyId: id,
        },
      });

      console.log("Response for the property:", response.data);
      setData(response.data.result[0]);

      if (
        response.data.result[0].filesURL &&
        response.data.result[0].filesURL.length > 0
      ) {
        const images = response.data.result[0].filesURL.map((file) => file.url);
        setDefaultImages(images);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const propertyDetails = [
    { left: "Property Id", value: data ? data._id : null },
    { left: "Property Name", value: data ? data.propertyName : null },
    { left: "RERA Number", value: data ? data.RERANumber : null },
    { left: "Area in(sq.ft.) ", value: data ? data.unitType : null },
    { left: "Country", value: data ? data.country : null },
    { left: "State", value: data ? data.state : null },
    { left: "City", value: data ? data.city : null },
    { left: "Category", value: data ? data.category : null },
    { left: "Form", value: data ? data.form : null },
    {
      left: "Construction Status",
      value: data ? data.constructionStatus : null,
    },
    { left: "Possession Date & Time", value: data ? new Date(data.possession).toLocaleString("en-IN", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "Asia/Kolkata",
    }).replace(",", "") : null },
    { left: "Completion Date & Time", value: data ? new Date(data.completedTime).toLocaleString("en-IN", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "Asia/Kolkata",
    }).replace(",", "") : null },
    { left: "Minimum Price", value: data ? data.minPrice : null },
    { left: "Maximum Price", value: data ? data.maxPrice : null },
    { left: "Description", value: data ? data.about : null },
    { left: "Status", value: data ? <span style={{ color: data.status === "ACTIVE" ? "green" : "red" }}>
    {data.status}
  </span> : null },
  ];

  

  

  const handleThumbnailClick = (index) => {
    setSelectedImage(index);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setCustomImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <MainLayout>
      <Grid container spacing={3} padding={2}>

        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
              Property Management
            </Typography>
            <Link to={"/propertymanagement"} style={{ textDecoration: "none" }}>
              <IconButton>
                <img
                  src={arrowBack}
                  alt="arroWback"
                  style={{
                    width: "2.813rem",
                    height: "2.813rem",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={0} sx={{ padding: 3 }}>
            <Typography variant="h5" fontWeight="bold" gutterBottom>
              Property Details
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  sx={{
                    padding: 2,
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={
                      customImage ||
                      (defaultImages.length > 0 && defaultImages[selectedImage])
                    }
                    alt="Property"
                    style={{
                      width: "100%",
                      height: "auto",
                      cursor: "pointer",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                    onDoubleClick={() =>
                      document.getElementById("imageInput").click()
                    }
                  />
                  <Box display="flex" justifyContent="center" mt={2}>
                    {defaultImages.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt="Thumbnail"
                        style={{
                          width: "60px",
                          height: "60px",
                          margin: "0 5px",
                          border:
                            index === selectedImage
                              ? "2px solid #1976d2"
                              : "2px solid transparent",
                          cursor: "pointer",
                          borderRadius: "4px",
                        }}
                        onClick={() => handleThumbnailClick(index)}
                      />
                    ))}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <ViewDetail type="property" details={propertyDetails} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

      </Grid>
    </MainLayout>
  );
};

export default ViewProperty;
