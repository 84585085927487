import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const LeadAnalysisCard = ({ backgroundImage, heading, count, width, height }) => {
  const cardStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    width: width || '380.73px',
    height: height || '187.04px',
    borderRadius: '10px',
    alignItems : 'center'
  };

  const cardContentStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', 
    height: '100%', 
  };


  const headingStyle = {
    fontWeight : 500,
    fontSize  : '15px'
  }
  const countStyle = {
    fontWeight : 500,
    fontSize  : '25px'
  }
  return (
    <Card style={cardStyle}>
      <CardContent style={cardContentStyle}>
        <Typography style={headingStyle}>
          {heading}
        </Typography>
        <Typography style={countStyle}>
          {count}
        </Typography>
      </CardContent>
    </Card>
  );
};


export default LeadAnalysisCard;
