import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import arrowBack from "../../assets/arrowBack.svg";

import { Grid, Typography } from "@mui/material";

export default function MaxWidthDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        size="small"
        sx={{
          backgroundColor: "transparent",
          "&:hover": { backgroundColor: "transparent" },
          color: "rgba(22, 30, 41, 1)",
          marginRight: "0.6rem",
          width: "12rem",
          height: "2rem",
        }}
        onClick={handleClickOpen}
      >
        <Typography
          fontFamily="Outfit"
          sx={{ textTransform: "none", fontSize: "0.8rem" }}
        >
          <strong>View Booked Details</strong>
        </Typography>
      </Button>

      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {},
        }}
      >
        <DialogTitle>
          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.4rem",
              alignItems: "center",
              marginBottom: "0.64rem",
            }}
          >
            <img
              src={arrowBack}
              alt="arroWback"
              style={{
                width: "2.113rem",
                height: "2.113rem",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />

            <strong>Confirmed Booking Details</strong>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <DialogContentText></DialogContentText>
          <Grid style={{ display: "flex", flexDirection: "column " }}>
            <Grid
              style={{
                display: "flex",
                flexDirection: "row ",
                justifyContent: "space-between",
                padding:"0.5rem"
              }}
            >
              <Typography>Booked Date</Typography>
              <Typography>
                <strong>15 July 2024</strong>
              </Typography>
            </Grid>
            <Grid
            style={{
              display: "flex",
              flexDirection: "row ",
              justifyContent: "space-between",
              padding:"0.5rem"
            }}
          >
              <Typography>Developer</Typography>
              <Typography>
                <strong>DLF Property</strong>
              </Typography>
            </Grid>
            <Grid
              style={{
                display: "flex",
                flexDirection: "row ",
                justifyContent: "space-between",
                padding:"0.5rem"
              }}
            >
              <Typography>Property</Typography>
              <Typography>
                <strong>ASD Building</strong>
              </Typography>
            </Grid>
            <Grid
              style={{
                display: "flex",
                flexDirection: "row ",
                justifyContent: "space-between",
                padding:"0.5rem"
              }}
            >
              <Typography>Unit Type</Typography>
              <Typography>
                <strong>23 sq.ft</strong>
              </Typography>
            </Grid>
            <Grid
              style={{
                display: "flex",
                flexDirection: "row ",
                justifyContent: "space-between",
                padding:"0.5rem"
              }}
            >
              <Typography>Unit Number</Typography>
              <Typography>
                <strong>23 FA</strong>
              </Typography>
            </Grid>
            <Grid
              style={{
                display: "flex",
                flexDirection: "row ",
                justifyContent: "space-between",
                padding:"0.5rem"
              }}
            >
              <Typography>Area</Typography>
              <Typography>
                <strong>23 sq.ft</strong>
              </Typography>
            </Grid>
            <Grid
              style={{
                display: "flex",
                flexDirection: "row ",
                justifyContent: "space-between",
                padding:"0.5rem"
              }}
            >
              <Typography>Total Cost</Typography>
              <Typography>
                <strong>34 lakhs</strong>
              </Typography>
            </Grid>
            <Grid
              style={{
                display: "flex",
                flexDirection: "row ",
                justifyContent: "space-between",
                padding:"0.5rem"
              }}
            >
              <Typography>Booking Amount Paid</Typography>
              <Typography>
                <strong>15 Lakhs</strong>
              </Typography>
            </Grid>
            <Grid
              style={{
                display: "flex",
                flexDirection: "row ",
                justifyContent: "space-between",
                padding:"0.5rem"
              }}
            >
              <Typography>Payment Type</Typography>
              <Typography>
                <strong>Cash</strong>
              </Typography>
            </Grid>
            <Grid
            style={{
              display: "flex",
              flexDirection: "row ",
              justifyContent: "space-between",
              padding:"0.5rem"
            }}
          >
              <Typography>Payment Plan</Typography>
              <Typography>
                <strong>Cheque</strong>
              </Typography>
            </Grid>
            <Grid
              style={{
                display: "flex",
                flexDirection: "row ",
                justifyContent: "space-between",
                padding:"0.5rem"
              }}
            >
              <Typography>Source of Funds:</Typography>
              <Typography>
                <strong>NA</strong>
              </Typography>
            </Grid>
            <Grid
              style={{
                display: "flex",
                flexDirection: "row ",
                justifyContent: "space-between",
                padding:"0.5rem"
              }}
            >
              <Typography>Loan%</Typography>
              <Typography>
                <strong>NA</strong>
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions></DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
