import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import MainLayout from "../../../layout/MainLayout";
import {
  Paper,
  Box,
  Typography,
  Grid,
  IconButton,
  Button,
} from "@mui/material";
import Input from "@mui/joy/Input";
import ButtonComponent from "../../../component/Button";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { putAPIHandler } from "../../../config/service";
import { externalUrlSchema } from "../../../schemas";
import toast from "react-hot-toast";
import arrowBack from "../../../assets/arrowBack.svg";

const EditExternalUrl = () => {
  const navigate = useNavigate();
  const state = useLocation();
  const urlData = state?.state?.urlData;

  console.log(urlData, "urlData for the view. ");

  const [queryParams, setQueryParams] = useState(urlData.queryParams || []);
  const [headers, setHeaders] = useState(urlData.headers || []);
  const [showQueryParamFields, setShowQueryParamFields] = useState(false);
  const [showHeaderFields, setShowHeaderFields] = useState(false);

  const handleAddItem = (list, setList, key, value) => {
    if (key && value) {
      setList([...list, { key, value }]);
    }
  };

  const handleRemoveItem = (list, setList, index) => {
    setList(list.filter((_, idx) => idx !== index));
  };

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const dataToSend = {
        urlId: urlData._id,
        title: values.title,
        baseUrl: values.baseUrl,
        queryParams,
        headers,
      };

      const response = await putAPIHandler({
        endPoint: "updateExternalUrl",
        dataToSend,
      });

      if (response && response.data.responseCode === 200) {
        toast.success("External URL updated successfully.");
        navigate("/urlmanagement");
      } else {
        toast.error("Error in updating external URL.");
      }
    } catch (error) {
      console.error("Error:", error);
      setSubmitting(false);
    }
  };

  const extractQueryParams = (externalUrl) => {
    try {
      const url = new URL(externalUrl);
      const params = Array.from(url.searchParams.entries()).map(
        ([key, value]) => ({
          key,
          value,
        })
      );
      return params;
    } catch {
      return [];
    }
  };

  return (
    <MainLayout>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" padding="1rem">
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            URL Management
          </Typography>
        </Box>

        <Box sx={{ padding: "15px", overflowY: "auto" }}>
          <Box display="flex" alignItems="center" mb={2}>
            <Link to="/urlmanagement">
              <img
                src={arrowBack}
                alt="arrowBack"
                style={{
                  width: "2.813rem",
                  height: "2.813rem",
                  cursor: "pointer",
                }}
              />
            </Link>
            <Typography
              variant="h5"
              sx={{ marginLeft: "1rem", fontWeight: 800 }}
            >
              Edit External URL
            </Typography>
          </Box>

          <Paper elevation={1} sx={{ padding: 4, borderRadius: "10px" }}>
            <Formik
              initialValues={{
                title: urlData.title || "",
                baseUrl: urlData.baseurl || "",
              }}
              validationSchema={externalUrlSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h6" fontWeight="bold">
                        General Information
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Field
                        as={Input}
                        name="title"
                        placeholder="Enter Title"
                        fullWidth
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="error"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Field
                        as={Input}
                        type="text"
                        name="baseUrl"
                        placeholder="Enter Base Url"
                        fullWidth
                      />
                      <ErrorMessage
                        name="baseUrl"
                        component="div"
                        className="error"
                      />
                    </Grid>

                    {/* Headers Section */}
                    <Grid item xs={12}>
                      <Typography variant="h6">Headers</Typography>
                    </Grid>
                    <Grid item xs={12} display="flex" alignItems="center">
                      <IconButton
                        color="primary"
                        onClick={() => setShowHeaderFields(!showHeaderFields)}
                      >
                        <AddIcon />
                      </IconButton>
                      <Typography variant="body1" marginLeft={2}>
                        Add Header
                      </Typography>
                    </Grid>

                    {showHeaderFields && (
                      <>
                        <Grid item xs={6}>
                          <Field
                            as={Input}
                            name="headerKey"
                            placeholder="Header Key"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            as={Input}
                            name="headerValue"
                            placeholder="Header Value"
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", justifyContent: "end" }}
                        >
                          <Button
                            variant="outlined"
                            color="success"
                            onClick={() => {
                              handleAddItem(
                                headers,
                                setHeaders,
                                values.headerKey,
                                values.headerValue
                              );
                              setFieldValue("headerKey", "");
                              setFieldValue("headerValue", "");
                            }}
                          >
                            {" "}
                            + Header{" "}
                          </Button>
                        </Grid>
                      </>
                    )}

                    {headers.length > 0 &&
                      headers.map((header, index) => (
                        <Grid item xs={12} key={index}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography>
                              {header.key}: {header.value}
                            </Typography>
                            <IconButton
                              color="error"
                              onClick={() =>
                                handleRemoveItem(headers, setHeaders, index)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </Grid>
                      ))}

                    {/* Query Parameters Section */}
                    <Grid item xs={12}>
                      <Typography variant="h6">Query Parameters</Typography>
                    </Grid>
                    <Grid item xs={12} display="flex" alignItems="center">
                      <IconButton
                        color="primary"
                        onClick={() =>
                          setShowQueryParamFields(!showQueryParamFields)
                        }
                      >
                        <AddIcon />
                      </IconButton>
                      <Typography variant="body1" marginLeft={2}>
                        Add Query Parameter
                      </Typography>
                    </Grid>

                    {showQueryParamFields && (
                      <>
                        <Grid item xs={6}>
                          <Field
                            as={Input}
                            name="queryParamKey"
                            placeholder="Query Parameter Key"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            as={Input}
                            name="queryParamValue"
                            placeholder="Query Parameter Value"
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", justifyContent: "end" }}
                        >
                          <Button
                            variant="outlined"
                            color="success"
                            onClick={() => {
                              handleAddItem(
                                queryParams,
                                setQueryParams,
                                values.queryParamKey,
                                values.queryParamValue
                              );
                              setFieldValue("queryParamKey", "");
                              setFieldValue("queryParamValue", "");
                            }}
                          >
                            {" "}
                            + Query Parameter{" "}
                          </Button>
                        </Grid>
                      </>
                    )}

                    {queryParams.length > 0 &&
                      queryParams.map((param, index) => (
                        <Grid item xs={12} key={index}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography>
                              {param.key}: {param.value}
                            </Typography>
                            <IconButton
                              color="error"
                              onClick={() =>
                                handleRemoveItem(
                                  queryParams,
                                  setQueryParams,
                                  index
                                )
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </Grid>
                      ))}

                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ButtonComponent
                        type="submit"
                        height="35px"
                        name="Update URL"
                        disabled={isSubmitting}
                      />
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
          
        </Box>
      </Box>
    </MainLayout>
  );
};

export default EditExternalUrl;
