import React from "react";
import { Grid, Paper, Typography } from "@mui/material";

const Footer = () => {
  const paperStyle = {
    paddingTop:"15px",
    height: "100%",
  };

  const footerContentStyle = {
    display: "flex",
    justifyContent:"center",
    alignItems: "center",
    padding: "1rem 1rem 0rem 1rem",
    backgroundColor: "rgba(0, 176, 80, 0.05)",
  };

  const footerContent = {
    fontFamily: "Lato",
    fontWeight: 500,
    fontSize: "18px",
    color: "rgba(0, 176, 80, 1)",
    
  };

  return (
    <Grid>
      <Paper elevation={0} style={paperStyle}>
        <Grid style={footerContentStyle}>
          <Typography style={footerContent}>
            Admin Panel Developed By Mobiloitte Technologies
          </Typography>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Footer;
