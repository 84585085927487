import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import { Button, Grid, Paper, Typography, Box } from "@mui/material";
import PaginationTable from "../../component/Table/ContentManagementData";
import ButtonComponent from "../../component/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ApiConfig } from "../../config/ApiConfig";

const columns = [
  { id: "contentType", label: "Content Type" },
  { id: "action", label: "Action" },
];

const StaticContentListing = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(ApiConfig.getAllStaticContent);

        if (response?.data?.responseCode === 200) {
          const formattedData = response.data.result.map((row) => ({
            ...row,
            id: row._id,
          }));
          setData(formattedData);
        }
      } catch (error) {
        console.error("An error occurred while fetching the data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <MainLayout>
      <Grid
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: "20px",
        }}
      >
        <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
          Content Management
        </Typography>
        <Grid>
          <ButtonComponent
            name={"+ Add Content"}
            borderRadius={"100px"}
            onClick={() => navigate("/contentmanagement/createcontent")}
          />
        </Grid>
      </Grid>

      <Grid style={{ padding: "15px" }}>
        <PaginationTable
          columns={columns}
          data={data.map((row) => ({
            contentType: row.contentType,
            action: (
              <Box
                sx={{ display: "flex", gap: "15px", justifyContent: "center" }}
              >
                <Button
                  variant="outlined"
                  onClick={() =>
                    navigate(`/contentmanagement/viewcontent/${row.id}`, {
                      state: { contentType: row.contentType },
                    })
                  }
                  sx={{
                    color: "#00A86B",
                    borderColor: "#00A86B",
                    textTransform: "none",
                    width: "25%",
                    "&:hover": {
                      borderColor: "#007B53",
                      color: "#007B53",
                    },
                  }}
                >
                  View
                </Button>
                <Button
                  variant="outlined"
                  onClick={() =>
                    navigate(`/contentmanagement/editcontent/${row.id}`, {
                      state: { contentType: row.contentType },
                    })
                  }
                  sx={{
                    color: "#00A86B",
                    borderColor: "#00A86B",
                    textTransform: "none",
                    width: "25%",
                    "&:hover": {
                      borderColor: "#007B53",
                      color: "#007B53",
                    },
                  }}
                >
                  Edit
                </Button>
              </Box>
            ),
          }))}
          rowsPerPageOptions={[10, 25]}
        />
      </Grid>
    </MainLayout>
  );
};

export default StaticContentListing;
