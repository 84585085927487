import React from "react";
import { Button, Typography } from "@mui/material/";

const ButtonComponent = (props) => {
  const handleButtonClick = () => {
    if (props.onClick && typeof props.onClick === 'function') {
      props.onClick();
    }
  };
  
  const buttonStyles = {
    width: props.width || "180px",
    height: props.height || "50px",
    border: props.border,
    fontWeight: props.fontWeight || 400,
    backgroundColor: props.disabled ? "grey" : (props.backgroundColor || "rgba(0, 176, 80, 1)"),
    borderRadius: props.borderRadius || "0.4rem",
    color: props.disabled ? "lightgrey" : (props.color || "white"),
    margin: props.margin || "1rem",
    "&:hover": {
      backgroundColor:
        props.disabled ? "grey" :
        props.hoverBackgroundColor ||
        props.backgroundColor ||
        "rgba(0, 176, 80, 1)",
    },
    textTransform: "none",
    fontSize: "0.9rem",
  };

  return (
    <Button
      variant="contained"
      size="medium"
      type="submit"
      sx={buttonStyles}
      onClick={handleButtonClick}
      disabled={props.disabled}
    >
      {props.children ? ( 
        props.children
      ) : (
        <Typography sx={{ textTransform: "none", fontSize: "0.9rem" }}>
          {props.name}
        </Typography>
      )}
    </Button>
  );
};

export default ButtonComponent;
