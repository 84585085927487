import React, { useState } from "react";
import { Box, Typography, Paper } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import MainLayout from "../../layout/MainLayout"; // Assuming you have this layout component
import ButtonComponent from "../../component/Button"; // Assuming a custom Button component
import arrowBack from "../../assets/arrowBack.svg"; // Import the image for the back arrow
import { postAPIHandler } from "../../config/service";
import { contentSchema } from "../../schemas";
import Textarea from "@mui/joy/Textarea";
import Input from "@mui/joy/Input";
import Select from "react-select";
import toast from "react-hot-toast";

const CreateContent = () => {
  const navigate = useNavigate();

  const contentTypeOptions = [
    { value: "FAQ", label: "FAQ" },
    { value: "AboutUs", label: "About Us" },
    { value: "PrivacyPolicy", label: "Privacy Policy" },
    { value: "TermsConditions", label: "Terms and Conditions" },
    { value: "Disclaimer", label: "Disclaimer" },
    { value: "Rules and Regulations", label: "Rules and Regulations" },
  ];

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const dataToSend = {
        contentType: values.contentType,
        contentTitle: values.contentTitle,
        description: values.description,
      };
  
      if (values.contentType === "FAQ") {
        dataToSend.question = values.question;
        dataToSend.answer = values.answer;
      }
  
      const response = await postAPIHandler({
        endPoint: "createStaticContent",
        dataToSend: dataToSend,
      });
      console.log(response.data);
  
      if (response && response.data.responseCode === 200) {
        toast.success("Content added successfully.");
        navigate("/contentmanagement");
      } else {
        toast.error("Error in adding content.");
      }
    } catch (error) {
      console.error("Error:", error);
      setSubmitting(false);
    }
  };
  

  return (
    <MainLayout>
      <Box display="flex" flexDirection="column">

        <Box display="flex" alignItems="center" padding="1rem">
          <Typography variant="h4" fontWeight={700}>
            Content Management
          </Typography>
        </Box>

        <Box sx={{ marginLeft: "15px", marginBottom: "15px" }}>
          <Paper
            elevation={0}
            sx={{ padding: "15px", overflowY: "auto", scrollbarWidth: "none" }}
          >
            <Box display="flex" alignItems="center" mb={2}>
              <Link to={"/contentmanagement"}>
                <img
                  src={arrowBack}
                  alt="arrowBack"
                  style={{
                    width: "2.813rem",
                    height: "2.813rem",
                    cursor: "pointer",
                  }}
                />
              </Link>
              <Typography variant="h5" sx={{ marginLeft: "1rem", fontWeight: 800 }}>
                Create Static Content
              </Typography>
            </Box>

            <Formik
              initialValues={{
                contentType: "",
                contentTitle: "",
                description: "",
                question: "",
                answer: "",
              }}
              validationSchema={contentSchema}
              onSubmit={onSubmit}
            >
             
              {({ isSubmitting, handleBlur, setFieldValue, values}) => (
                <Form>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                  >
                    <Box mb={2} width="100%" maxWidth="500px">
                      <Select
                        name="contentType"
                        options={contentTypeOptions}
                        value={contentTypeOptions.find(
                          (option) => option.value === values.contentType
                        )}
                        onChange={(selectedOption) =>
                          setFieldValue(
                            "contentType",
                            selectedOption ? selectedOption.value : ""
                          )
                        }
                        onBlur={handleBlur}
                        isClearable
                        placeholder="Select Content Type"
                        fullWidth
                      />
                      <ErrorMessage
                        name="contentType"
                        component="div"
                        className="error"
                      />
                    </Box>

                    <Box mb={2} width="100%" maxWidth="500px">
                      <Field
                        as={Input}
                        type="text"
                        name="contentTitle"
                        placeholder="Enter Content Title"
                        fullWidth
                      />
                      <ErrorMessage
                        name="contentTitle"
                        component="div"
                        className="error"
                      />
                    </Box>

                    <Box mb={2} width="100%" maxWidth="500px">
                      <Field
                        as={Textarea}
                        minRows={4}
                        name="description"
                        placeholder="Enter Description"
                        fullWidth
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="error"
                      />
                    </Box>

                    {values.contentType === "FAQ" && (
                      <>
                        <Box mb={2} width="100%" maxWidth="500px">
                          <Field
                            as={Textarea}
                            minRows={4}
                            name="question"
                            placeholder="Enter Question"
                            fullWidth
                          />
                          <ErrorMessage
                            name="question"
                            component="div"
                            className="error"
                          />
                        </Box>

                        <Box mb={2} width="100%" maxWidth="500px">
                          <Field
                            as={Textarea}
                            minRows={4}
                            name="answer"
                            placeholder="Enter Answer"
                            fullWidth
                          />
                          <ErrorMessage
                            name="answer"
                            component="div"
                            className="error"
                          />
                        </Box>
                      </>
                    )}

                    <Box
                      mb={2}
                      width="100%"
                      display="flex"
                      justifyContent="center"
                    >
                      <ButtonComponent
                        type="submit"
                        name="Add Content"
                        disabled={isSubmitting}
                        height="43px"
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>

          </Paper>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default CreateContent;
