import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {  Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, TextField, InputAdornment } from "@mui/material";

const InputField = ({ 
  type,
  name,
  placeholder,
  color,
  backgroundColor,
  borderRadius,
  height,
  onChange,
  onBlur,
  maxLength,
  value,
  restrictCopypaste
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleCopypaste = (e) => {
    if(restrictCopypaste){
      e.preventDefault()
    }
  }

  return (
    <div >
      <TextField
        type={showPassword ? 'text' : type}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onCopy={handleCopypaste}
        onCut={handleCopypaste}
        onPaste={handleCopypaste}
        maxLength={maxLength}
        className='custom-input-field'
        inputProps={{maxLength:maxLength}}
        InputProps={{
          endAdornment: type === 'password' && (
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility} edge="end">
                {!showPassword ? <VisibilityOff style={{fontSize: '30px'}} /> : <Visibility style={{fontSize: '30px'}} />}
              </IconButton>
            </InputAdornment>
          )
        }}
        
        style={{
          color: color,
          backgroundColor: backgroundColor,
          borderRadius: borderRadius,
          border: 'none',
          outline: 'none',
          width: '100%',
          height: height,
          boxSizing: 'border-box',
          fontSize:'14px',
          fontWeight: '300',
          fontStyle:'none',
          fontFamily:"'Mulish', sans-serif",

        }}

      />
     
    </div>
  );
};

InputField.propTypes = {
  // type: PropTypes.string.isRequired,
  // placeholder: PropTypes.string.isRequired,
  // label: PropTypes.string,
  // color: PropTypes.string.isRequired,
  // backgroundColor: PropTypes.string.isRequired,
  borderRadius: PropTypes.string.isRequired,
};

export default InputField;