import React, { useEffect, useState } from "react";
import MainLayout from "../../../layout/MainLayout";
import {
  Paper,
  TextField,
  Grid,
  Typography,
  Box,
  Checkbox,
  styled,
  CircularProgress,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ButtonComponent from "../../../component/Button";
import "./EditProperty.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getAPIHandler,
  postAPIHandler,
  putAPIHandler,
} from "../../../config/service";
import arrowBack from "../../../assets/arrowBack.svg";
import Select from "react-select";
import { State, City } from "country-state-city";
import { propertySchema } from "../../../schemas";
import axios from "axios";
import dayjs from "dayjs";
import BubbleLoader from "../../../component/Loader/BubbleLoader";
import toast from "react-hot-toast";

const CheckboxAll = styled(Checkbox)({
  "&.MuiCheckbox-root": {
    padding: "9px 6px 9px 0px",
    color: "#2e6cd7",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "28px",
  },
});

const EditProperty = () => {
  const [loading, setLoading] = useState(false);
  const [accountloading, setAccountLoading] = useState(false);
  const [imageloading, setImageLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [customImage, setCustomImage] = useState(null);
  const [uploadedFileURLs, setUploadedFileURLs] = useState([]);
  const [data, setData] = useState({});
  const [images, setImages] = useState([
    "https://cdn.pixabay.com/photo/2016/11/29/03/53/house-1867187_1280.jpg",
  ]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const navigate = useNavigate();
  const { propertyId } = useParams();
  console.log(propertyId, "propertyId")
  const [filteredFormOptions, setFilteredFormOptions] = useState([]);
  const [allAdsAccount, setAllAdsAccount] = useState([]);
  const [allRolePages, setAllRolePages] = useState([]);
  const [developerOptions, setdeveloperOptions] = useState([]);
  const countryOptions = [{ value: "IN", label: "India" }];
  const categoryOptions = [
    { value: "Residential", label: "Residential" },
    { value: "Commercial", label: "Commercial" },
    { value: "Villa", label: "Villa" },
  ];

  const categoryFormMapping = {
    Residential: [
      { value: "1 BHK", label: "1 BHK" },
      { value: "2 BHK", label: "2 BHK" },
      { value: "2.5 BHK", label: "2.5 BHK" },
      { value: "3 BHK", label: "3 BHK" },
      { value: "3.5 BHK", label: "3.5 BHK" },
      { value: "4 BHK", label: "4 BHK" },
      { value: "4.5 BHK", label: "4.5 BHK" },
      { value: "5 BHK", label: "5 BHK" },
      { value: "Penthouse", label: "Penthouse" },
      { value: "Duplex", label: "Duplex" },
      { value: "Plot", label: "Plot" },
      { value: "Farmhouse", label: "Farmhouse" },
      { value: "Studio Apartments", label: "Studio Apartments" },
    ],
    Commercial: [
      { value: "Shop", label: "Shop" },
      { value: "Food Court", label: "Food Court" },
      { value: "Office Space", label: "Office Space" },
      { value: "Industrial Land", label: "Industrial Land" },
      { value: "Shed", label: "Shed" },
      { value: "SCO", label: "SCO" },
    ],
    Villa: [
      { value: "Plot", label: "Plot" },
      { value: "Farmhouse", label: "Farmhouse" },
      { value: "Duplex", label: "Duplex" },
    ],
  };

  const typeOptions = [
    { value: "Under Construction", label: "Under Construction" },
    { value: "Ready to Move", label: "Ready to Move" },
  ];

  const priceOptions = [
    { value: "Lakh", label: "Lakh" },
    { value: "Crore", label: "Crore" },
  ];

  useEffect(() => {
    fetchStateOptions();
    fetchAdsAccountAndPages();
    fetchDeveloperOptions();
    getData();
  }, []);

  const userData = JSON.parse(sessionStorage.getItem("user"));

  const { userRoleType, id } = userData || {};

  const fetchStateOptions = () => {
    const states = State.getStatesOfCountry("IN").map((state) => ({
      value: state.isoCode,
      label: state.name,
    }));
    setStateOptions(states);
  };

  const fetchDeveloperOptions = async () => {
    setAccountLoading(true)
    try {
      const developerResponse = await getAPIHandler({
        endPoint: "listAllDevelopers",
      });

      console.log(
        developerResponse.data.result.developers,
        "developerResponse"
      );

      const mapUsers = (users) =>
        users.map((user) => ({
          value: user._id,
          label: `${user.fullName}`,
        }));

      const mappedUsers = mapUsers(developerResponse.data.result.developers);

      const sortedMappedUsers = mappedUsers.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      setdeveloperOptions(sortedMappedUsers);
    } catch (error) {
      console.error("Error fetching user options:", error);
    }
    setAccountLoading(false)
  };

  const handleStateChange = (selectedState, setFieldValue) => {
    if (!selectedState) {
      setCityOptions([]);
      setFieldValue("city", "");
      return;
    }

    const cities = City.getCitiesOfState("IN", selectedState.value).map(
      (city) => ({
        value: city.name,
        label: city.name,
      })
    );
    setCityOptions(cities);
    setFieldValue("city", "");
  };

  const getLongLat = async (location) => {
    try {
      const response = await axios.get(
        `https://geocode.xyz/${encodeURIComponent(
          location
        )}?json=1&auth=37130815437857150226x10414`
      );

      const { latt: lat, longt: lon } = response.data;
      return { lat, lon };
    } catch (error) {
      console.error("Error fetching coordinates: ", error);
      return { lat: null, lon: null };
    }
  };

  const fetchAdsAccountAndPages = async () => {
    try {
      const pageResponse = await getAPIHandler({
        endPoint: "getAllAdsAccountsAndRolePages",
      });

      if (pageResponse && pageResponse.data && pageResponse.data.result) {
        const { accountDetails, allRolePages } = pageResponse.data.result;

        if (accountDetails && Array.isArray(accountDetails)) {
          setAllAdsAccount(accountDetails);
        } else {
          console.error("Account details not found in the response");
        }

        if (allRolePages && Array.isArray(allRolePages)) {
          setAllRolePages(allRolePages);
        } else {
          console.error("Role pages not found in the response");
        }
      } else {
        console.error("Invalid API response structure:", pageResponse);
      }
    } catch (error) {
      console.error("Error fetching ad accounts and role pages:", error);
    }
  };

  const onSubmit = async (values, { setSubmitting }) => {
    console.log(values, "values")
    setLoading(true);
    try {
      const existingImages = data.filesURL.map((file) => ({
        fileType: "IMAGE",
        url: file.url,
      }));

      const newImages = uploadedFileURLs.map((url) => ({
        fileType: "IMAGE",
        url: url,
      }));

      const allImages = [...existingImages, ...newImages];

      const { lat, lon } = await getLongLat(values.location);

      if (lat === "0.00000" || lon === "0.00000") {
        toast.error(
          "Failed to fetch coordinates. Please enter a valid location."
        );
        setLoading(false);
        return;
      }

      const response = await putAPIHandler({
        endPoint: "updateProperty",
        dataToSend: {
          propertyId: propertyId,
          adAccountId: values.selectedAdAccount,
          pageId: values.selectedPage,
          pageToken: values.accessToken,
          developerId: values.selectedDeveloper,
          propertyName: values.propertyName,
          RERANumber: values.areaNumber,
          unitType: values.areaFt,
          address: values.location,
          country: values.country,
          state: values.state,
          city: values.city,
          latitude: lat,
          longitude: lon,
          form: values.form,
          possession: values.listedDate,
          completedTime: values.possessionDate,
          about: values.description,
          category: values.category,
          constructionStatus: values.constructionStatus,
          minPrice: `${values.minPrice} ${values.PriceDropdownMin}`,
          maxPrice: `${values.maxPrice} ${values.PriceDropdownMax}`,
          memberType: userRoleType,
          memberId: id,
          filesURL: allImages,
          postAd: values.postAd,
        },
      });

      console.log(response.data.result);

      setSubmitting(false);

      navigate("/propertymanagement");
    } catch (error) {
      console.error("Error:", error);
      setSubmitting(false);
    }
    setLoading(false);
  };

  const getData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "viewPropertyDetails",
        paramsData: {
          propertyId: propertyId,
        },
      });

      console.log("Response to update the property details:", response.data);
      const propertyDetails = response.data.result[0];
      setData(propertyDetails);
      if (propertyDetails.filesURL.length > 0) {
        setImages(propertyDetails.filesURL.map((file) => file.url));
        setSelectedImage(propertyDetails.filesURL[0].url);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFileSelect = async (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        setImageLoading(true);
        try {
          const reader = new FileReader();
          reader.onload = () => {
            setCustomImage(reader.result);
          };
          reader.readAsDataURL(file);

          const formData = new FormData();
          formData.append("file", file);

          const res = await postAPIHandler({
            endPoint: "uploadFile",
            dataToSend: formData,
          });

          console.log("File upload response:", res);

          setUploadedFileURLs((prevURLs) => [...prevURLs, res.data.result]);
          setImages((prevImages) => [...prevImages, res.data.result]);
          setSelectedImage(res.data.result);
        } catch (error) {
          console.error("Error uploading file:", error);
        }
        setImageLoading(false);
      } else {
        alert("Please select an propertyImage file.");
      }
    }
  };

  const handleThumbnailClick = (index) => {
    setSelectedImage(images[index]);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setCustomImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const renderAddPhotosButtonAndInput = () => (
    <Grid>
      <ButtonComponent
        height="43px"
        onClick={() => document.getElementById("imageInput").click()}
      >
        {imageloading ? (
          <BubbleLoader color="#FFF" size="0.7rem" />
        ) : (
          "Upload Images"
        )}{" "}
      </ButtonComponent>
      <input
        id="imageInput"
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleFileSelect}
      />
    </Grid>
  );

  return (
    <MainLayout>
      <Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            Property Management
          </Typography>
        </Grid>

        <Grid style={{ marginLeft: "15px", marginBottom: "15px" }}>
          <Paper
            elevation={0}
            style={{
              padding: "1rem",
              overflowY: "auto",
              scrollbarWidth: "none",
            }}
          >
            <div className="addproperty-header">
              <div className="arrow">
                <Link to={"/propertymanagement"}>
                  <img
                    src={arrowBack}
                    alt="arroWback"
                    style={{
                      width: "2.813rem",
                      height: "2.813rem",
                      cursor: "pointer",
                    }}
                  />
                </Link>
                <Typography
                  variant="h5"
                  sx={{ marginLeft: "1rem", fontWeight: 800 }}
                >
                  Update Property
                </Typography>
              </div>
            </div>

            <Box display="flex" justifyContent="center" margin="2rem">
              <Typography
                variant="h6"
                sx={{ marginLeft: "1rem", fontWeight: 700 }}
              >
                Property Details
              </Typography>
            </Box>

            <div className="add-property-container">
              <div className="add-property-content">
                <div
                  className="image-section"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  <div className="main-image-container">
                    {customImage || selectedImage ? (
                      <img
                        src={customImage || selectedImage}
                        alt="Custom"
                        style={{ width: "100%", maxWidth: "600px" }}
                      />
                    ) : (
                      <Grid>
                        <div className="upload-overlay">
                          <span className="plus-icon">+</span>
                          <strong className="upload-message">
                            Drag and drop images to upload
                          </strong>
                        </div>
                        <img
                          src={images[selectedImage]}
                          alt="Custom"
                          style={{ width: "100%", maxWidth: "600px" }}
                          onDoubleClick={() =>
                            document.getElementById("imageInput").click()
                          }
                        />
                      </Grid>
                    )}
                  </div>
                  {images.length > 1 && (
                    <div className="thumbnails">
                      {images.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          alt="Thumbnail"
                          className={index === selectedImage ? "active" : ""}
                          onClick={() => handleThumbnailClick(index)}
                        />
                      ))}
                    </div>
                  )}
                  {renderAddPhotosButtonAndInput()}
                </div>
              </div>

              <div className="add-property-content">
                <Formik
                initialValues={{
                  selectedDeveloper: data ? data.developerId : "",
                  propertyName: data ? data.propertyName : "",
                  areaNumber: data ? data.RERANumber : "",
                  areaFt: data ? data.unitType : "",
                  country: data ? data.country : "",
                  state: data ? data.state : "",
                  city: data ? data.city : "",
                  category: data ? data.category : "",
                  form: data ? data.form : "",
                  constructionStatus: data ? data.constructionStatus : "",
                  listedDate: data && data.possession ? dayjs(data.possession) : null, 
                  possessionDate: data && data.completedTime ? dayjs(data.completedTime) : null, 
                  location: data ? data.address : "",
                  minPrice: data ? data.minPrice?.split(" ")[0] : "",
                  maxPrice: data ? data.maxPrice?.split(" ")[0] : "",
                  PriceDropdownMin: data ? data.minPrice?.split(" ")[1] : "",
                  PriceDropdownMax: data ? data.maxPrice?.split(" ")[1] : "",
                  description: data ? data.about : "",
                  vpType: data ? data.memberType : "",
                  vpTypeOptions: data ? data.memberId : "",
                  filesURL: data ? data.filesURL : [],
                  selectedAdAccount: data ? data.adAccountId : "",
                  selectedPage: data ? data.pageId : "",
                  postAd: data ? data.postAd : "",
              }}
              
                  enableReinitialize={true}
                  validationSchema={propertySchema}
                  onSubmit={onSubmit}
                >
                  {({ values, setFieldValue, handleBlur, handleChange }) => (
                    <Form>
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Select Ads Account{" "}
                              <span style={{ color: "red" }}>*</span> :
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Select
                              className="select non-editable-select"
                              name="selectedAdAccount"
                              options={allAdsAccount.map((account) => ({
                                value: account.account_id,
                                label: account.account_name,
                              }))}
                              value={
                                allAdsAccount.find(
                                  (account) =>
                                    account.account_id ===
                                    values.selectedAdAccount
                                )
                                  ? {
                                      value: values.selectedAdAccount,
                                      label: allAdsAccount.find(
                                        (account) =>
                                          account.account_id ===
                                          values.selectedAdAccount
                                      ).account_name,
                                    }
                                  : null
                              }
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  "selectedAdAccount",
                                  selectedOption ? selectedOption.value : ""
                                );
                              }}
                              onBlur={handleBlur}
                              isClearable={false}
                              isLoading={accountloading}
                              loadingMessage={() => (
                                <div className="loading-indicator">
                                  <CircularProgress
                                    size={24}
                                    sx={{ color: "rgb(0, 176, 80)" }}
                                  />
                                </div>
                              )}
                              placeholder="Select Ads Account"
                            />
                            <ErrorMessage
                              name="selectedAdAccount"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Select Page{" "}
                              <span style={{ color: "red" }}>*</span> :
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Select
                              className="select"
                              name="selectedPage"
                              options={allRolePages.map((page) => ({
                                value: page.id,
                                label: page.name,
                              }))}
                              value={
                                allRolePages.find(
                                  (page) => page.id === values.selectedPage
                                )
                                  ? {
                                      value: values.selectedPage,
                                      label: allRolePages.find(
                                        (page) =>
                                          page.id === values.selectedPage
                                      ).name,
                                    }
                                  : null
                              }
                              onChange={(selectedOption) => {
                                const selectedPage = allRolePages.find(
                                  (page) => page.id === selectedOption.value
                                );
                                setFieldValue(
                                  "selectedPage",
                                  selectedOption ? selectedOption.value : ""
                                );
                                setFieldValue(
                                  "accessToken",
                                  selectedPage ? selectedPage.access_token : ""
                                );
                              }}
                              onBlur={handleBlur}
                              isClearable={true}
                              isLoading={accountloading}
                              loadingMessage={() => (
                                <div className="loading-indicator">
                                  <CircularProgress
                                    size={24}
                                    sx={{ color: "rgb(0, 176, 80)" }}
                                  />
                                </div>
                              )}
                              placeholder="Select Page"
                            />
                            <ErrorMessage
                              name="selectedPage"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Select Developer{" "}
                              <span style={{ color: "red" }}>*</span>:
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Select
                              className="select"
                              name="selectedDeveloper"
                              options={developerOptions}
                              value={developerOptions.find(
                                (developer) =>
                                  developer.value === values.selectedDeveloper
                              )}
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  "selectedDeveloper",
                                  selectedOption ? selectedOption.value : ""
                                );
                              }}
                              onBlur={handleBlur}
                              isClearable={true}
                              isLoading={accountloading}
                              loadingMessage={() => (
                                <div className="loading-indicator">
                                  <CircularProgress
                                    size={24}
                                    sx={{ color: "rgb(0, 176, 80)" }}
                                  />
                                </div>
                              )}
                              placeholder="Select Developer"
                            />
                            <ErrorMessage
                              name="selectedDeveloper"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Property Name{" "}
                              <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Field
                              as={TextField}
                              type="text"
                              name="propertyName"
                              variant="outlined"
                              placeholder="Enter property name"
                              className="form-control"
                              onKeyPress={(e) => {
                                if (e.target.value.length === 0) {
                                  if (e.key === " ") e.preventDefault();
                                }
                                if (
                                  e.target.value.length >= 60 &&
                                  e.key !== "Backspace"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <ErrorMessage
                              name="propertyName"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>RERA Number </strong>
                          </label>
                          <div className="errordiv">
                            <Field
                              as={TextField}
                              type="text"
                              name="areaNumber"
                              variant="outlined"
                              placeholder="Enter RERA number"
                              className="form-control"
                              onKeyPress={(e) => {
                                if (e.target.value.length === 0) {
                                  if (e.key === " ") e.preventDefault();
                                }
                                if (
                                  e.target.value.length >= 60 &&
                                  e.key !== "Backspace"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <ErrorMessage
                              name="areaNumber"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Area in(sq ft.){" "}
                              <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Field
                              as={TextField}
                              type="text"
                              name="areaFt"
                              variant="outlined"
                              placeholder="Area in(sq ft.)"
                              className="form-control"
                              onKeyPress={(e) => {
                                if (e.target.value.length === 0) {
                                  if (e.key === " ") e.preventDefault();
                                }
                                if (
                                  e.target.value.length >= 60 &&
                                  e.key !== "Backspace"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <ErrorMessage
                              name="areaFt"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Country <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Select
                              className="select"
                              name="country"
                              options={countryOptions}
                              value={countryOptions.find(
                                (option) => option.label === values.country
                              )}
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  "country",
                                  selectedOption ? selectedOption.label : ""
                                )
                              }
                              onBlur={handleBlur}
                              isClearable={true}
                              placeholder="Select country"
                              // isDisabled={true} // Makes the field non-editable
                            />
                            <ErrorMessage
                              name="country"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              State <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Select
                              className="select"
                              name="state"
                              options={stateOptions}
                              value={stateOptions.find(
                                (option) => option.label === values.state
                              )}
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  "state",
                                  selectedOption ? selectedOption.label : ""
                                );
                                handleStateChange(
                                  selectedOption,
                                  setFieldValue
                                );
                              }}
                              onBlur={handleBlur}
                              isClearable={true}
                              placeholder="Select state"
                            />
                            <ErrorMessage
                              name="state"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              City <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Select
                              className="select"
                              name="city"
                              options={cityOptions}
                              value={cityOptions.find(
                                (option) => option.label === values.city
                              )}
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  "city",
                                  selectedOption ? selectedOption.label : ""
                                )
                              }
                              // isClearable={true}
                              placeholder="Select city"
                            />
                            <ErrorMessage
                              name="city"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Category <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Select
                              className="select"
                              name="category"
                              options={categoryOptions}
                              value={categoryOptions.find(
                                (option) => option.value === values.category
                              )}
                              onChange={(selectedOption) => {
                                const selectedCategory = selectedOption
                                  ? selectedOption.value
                                  : "";
                                setFieldValue("category", selectedCategory);

                                if (selectedCategory) {
                                  setFilteredFormOptions(
                                    categoryFormMapping[selectedCategory] || []
                                  );
                                } else {
                                  setFilteredFormOptions([]);
                                }
                              }}
                              onBlur={handleBlur}
                              isClearable={true}
                              placeholder="Select Category"
                            />
                            <ErrorMessage
                              name="category"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Form <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Select
                              className="select"
                              name="form"
                              options={filteredFormOptions}
                              value={filteredFormOptions.find(
                                (option) => option.value === values.form
                              )}
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  "form",
                                  selectedOption ? selectedOption.value : ""
                                )
                              }
                              onBlur={handleBlur}
                              isClearable={true}
                              placeholder="Select Form"
                            />
                            <ErrorMessage
                              name="form"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Construction Status
                              <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Select
                              className="select"
                              name="constructionStatus"
                              options={typeOptions}
                              value={typeOptions.find(
                                (option) =>
                                  option.value === values.constructionStatus
                              )}
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  "constructionStatus",
                                  selectedOption ? selectedOption.value : ""
                                )
                              }
                              onBlur={handleBlur}
                              isClearable={true}
                              placeholder="Select construction status"
                            />
                            <ErrorMessage
                              name="constructionStatus"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                      <div className="developer-title">
                          <label>
                              <strong>
                                  Completed Time
                                  <span style={{ color: "red" }}>*</span> :{" "}
                              </strong>
                          </label>
                          <div className="errordiv">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                      name="listedDate"
                                      disablePast
                                      sx={{
                                          width: "23rem",
                                          height: "2.4",
                                          "& .MuiInputBase-input": {
                                              fontSize: "15px",
                                              padding: "0.7rem",
                                          },
                                      }}
                                      value={values.listedDate ? dayjs(values.listedDate) : null} // Ensure this is a Day.js object
                                      onChange={(newDate) => {
                                          const formattedDate = newDate ? newDate.format("YYYY-MM-DD") : null; // Format the date
                                          setFieldValue("listedDate", formattedDate); // Update the field value
                                      }}
                                      renderInput={(params) => <TextField {...params} />}
                                  />
                              </LocalizationProvider>
                              <ErrorMessage
                                  name="listedDate"
                                  component="div"
                                  className="error"
                              />
                          </div>
                      </div>
                  </div>
                  

                      <div className="form-group">
                      <div className="developer-title">
                          <label>
                              <strong>
                                  Possession<span style={{ color: "red" }}>*</span> :
                              </strong>
                          </label>
                          <div className="errordiv">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                      name="possessionDate"
                                      sx={{
                                          width: "23rem",
                                          height: "2.4",
                                          "& .MuiInputBase-input": {
                                              fontSize: "15px",
                                              padding: "0.7rem",
                                          },
                                      }}
                                      value={
                                          values.possessionDate
                                              ? dayjs(values.possessionDate) // Ensure it's a Day.js object
                                              : null
                                      }
                                      onChange={(newDate) => {
                                          const formattedDate = newDate
                                              ? newDate.format("YYYY-MM-DD") // Keep the format consistent
                                              : null;
                                          setFieldValue("possessionDate", formattedDate);
                                      }}
                                      renderInput={(params) => <TextField {...params} />}
                                  />
                              </LocalizationProvider>
                              <ErrorMessage
                                  name="possessionDate"
                                  component="div"
                                  className="error"
                              />
                          </div>
                      </div>
                  </div>
                  
                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              location <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <Field
                              as={TextField}
                              type="text"
                              name="location"
                              variant="outlined"
                              className="form-control"
                              onKeyPress={(e) => {
                                if (e.target.value.length === 0) {
                                  if (e.key === " ") e.preventDefault();
                                }
                                if (
                                  e.target.value.length >= 60 &&
                                  e.key !== "Backspace"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(event) => {
                                handleChange(event);
                                getLongLat(event, setFieldValue);
                              }}
                            />
                            <ErrorMessage
                              name="location"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="developer-title">
                          <label>
                            <strong>
                              Price Range<span style={{ color: "red" }}>*</span>{" "}
                              :{" "}
                            </strong>
                          </label>
                          <div className="errordiv">
                            <div className="price-range">
                              <div className="price-content">
                                <span>Min:</span>
                                <Field
                                  as={TextField}
                                  type="number"
                                  name="minPrice"
                                  variant="outlined"
                                  className="form-price"
                                  onKeyPress={(e) => {
                                    if (e.target.value.length === 0) {
                                      if (e.key === " ") e.preventDefault();
                                    }
                                    if (
                                      e.target.value.length >= 60 &&
                                      e.key !== "Backspace"
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  value={values.minPrice}
                                />
                                <Select
                                  className="price-dropdown"
                                  name="PriceDropdownMin"
                                  options={priceOptions}
                                  value={priceOptions.find(
                                    (option) =>
                                      option.value === values.PriceDropdownMin
                                  )}
                                  onChange={(selectedOption) =>
                                    setFieldValue(
                                      "PriceDropdownMin",
                                      selectedOption ? selectedOption.value : ""
                                    )
                                  }
                                  onBlur={handleBlur}
                                  isClearable={true}
                                  placeholder="Unit"
                                />
                              </div>

                              <div className="price-content">
                                <span>Max:</span>
                                <Field
                                  as={TextField}
                                  type="number"
                                  name="maxPrice"
                                  variant="outlined"
                                  className="form-price"
                                  onKeyPress={(e) => {
                                    if (e.target.value.length === 0) {
                                      if (e.key === " ") e.preventDefault();
                                    }
                                    if (
                                      e.target.value.length >= 60 &&
                                      e.key !== "Backspace"
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  value={values.maxPrice}
                                />
                                <Select
                                  className="price-dropdown"
                                  name="PriceDropdownMax"
                                  options={priceOptions}
                                  value={priceOptions.find(
                                    (option) =>
                                      option.value === values.PriceDropdownMax
                                  )}
                                  onChange={(selectedOption) =>
                                    setFieldValue(
                                      "PriceDropdownMax",
                                      selectedOption ? selectedOption.value : ""
                                    )
                                  }
                                  onBlur={handleBlur}
                                  isClearable={true}
                                  placeholder="Unit"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="developer-title">
                          <label htmlFor="description">
                            <strong>About:</strong>
                          </label>
                          <div className="errordiv">
                            <Field
                              as={TextField}
                              multiline
                              rows={4}
                              name="description"
                              variant="outlined"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="description"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="developer-title">
                          <CheckboxAll
                            checked={values.postAd}
                            onChange={() =>
                              setFieldValue("postAd", !values.postAd)
                            }
                          />
                          <label htmlFor="description">
                            <strong>Post ad in facebook</strong>
                          </label>
                        </div>
                      </div>

                      <div className="button-container">
                        <ButtonComponent type="submit" height="43px">
                          {loading ? (
                            <BubbleLoader color="#FFF" size="0.7rem" />
                          ) : (
                            "Update Property"
                          )}
                        </ButtonComponent>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default EditProperty;
