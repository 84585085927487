import React from "react";
import MainLayout from "../../layout/MainLayout";
import CustomizedTables from "../../component/Table/ReportManagementData";
import ButtonComponent from "../../component/Button";
import ReportFilter from "../../component/Filters/ReportFilter";
import { Grid, Typography, Paper } from "@mui/material";

const ReportManagement = () => {
  return (
    <MainLayout>
      <Grid>
        <Grid
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "20px",
          }}
        >
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            Report Management
          </Typography>
          <Grid>
            <ButtonComponent
              name={"Download CSV"}
              borderRadius={"100px"}
              backgroundColor={"rgba(0, 0, 0, 0.05)"}
              color={"black"}
            />
          </Grid>
        </Grid>

        <Grid style={{ padding: "15px" }}>
          <ReportFilter />
        </Grid>

        <Grid style={{ padding: "15px" }}>
          <CustomizedTables />
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default ReportManagement;
