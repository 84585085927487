import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import Card from "../Card/Card.js";
import "./LeadDetailHistory.css";
import { useParams } from "react-router-dom";
import { getAPIHandler } from "../../config/service.js";

const LeadDetailHistory = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [history, setHistory] = useState([]);
  const statusMap = {
    RESENTREQUEST: "Resent Request",
    GENERATEDLEAD: "Generated Lead",
    BOOKEDVISIT: "Booked Visit"
  };

  const getData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "getLeadsDetails",
        paramsData: {
          generatedLeadId: id,
        },
      });

      console.log("Response:", response.data);
      setData(response.data.result.leadDetails[0]);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const getHistory = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "getAllLeadHistory",
        paramsData: {
          requestedLeadId: id,
        },
      });

      console.log("Response:", response.data);
      setHistory(response.data.result.history || [])
    } catch (error) {
      console.error("Error:", error);
      setHistory([]);
    }
  };

  useEffect(() => {
    getData();
    getHistory();
  }, [id]);

  if (!data) {
    return <div>Loading...</div>;
  }
  
  return (
    <Paper
      elevation={1}
      style={{ width: "75vw", borderRadius: "10px", overflow: "hidden" }}
    >
      <div className="LeadDetailReq-container">
        <div className="LeadDetailReq-wrapper">
          <div className="LeadDetailReq-content">
            <div className="LeadDetailHistroy-left">
              <div className="data">
                <strong>Client Details:</strong>
              </div>
              <div>
                Name : <strong>{data.clientName}</strong>
              </div>
              <div>
                Phone No: <strong>{data.clientNumber}</strong>
              </div>
              <div>
                Email: <strong>{data.clientEmail}</strong>
              </div>
              <div>
                Request Received On: <strong>{new Date(data.createdAt).toLocaleString('en-IN', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  timeZone: 'Asia/Kolkata',
                })}</strong>
              </div>
              <div>
                Status: <strong>{statusMap[data.leadStatus]}</strong>
              </div>
            </div>
          </div>

          <div className="LeadDetailReq-content">
            <div className="LeadDetailReq-right">
              <div className="history">
                <strong>History</strong>
              </div>
              <div
                className="history-data"
                style={{
                  maxHeight: "600px",
                  overflowY: "auto",
                  padding: "10px",
                }}
              >  
              {history.length === 0 ? (
                <div><strong>No Lead History Avilable.</strong></div>
              ) : (
                history.map((item, index) => (
                  <div key={index} className="card-container">
                    <Card
                      visit={new Date(item.createdAt).toLocaleString('en-IN', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        timeZone: 'Asia/Kolkata',
                      })}
                      developer={item.leadDetails.developerDetails?.fullName || "N/A"}
                      teamMember={item.executiveId}
                      propertyName={item.leadDetails.propertyDetails?.propertyName || "N/A"}
                    />
                  </div>
                ))
              )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default LeadDetailHistory;
