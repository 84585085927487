import React from "react";
import MainLayout from "../../../layout/MainLayout";
import { Grid, Typography, Paper } from "@mui/material";
import arrowBack from "../../../assets/arrowBack.svg";
import { Link, useLocation } from "react-router-dom";
import ViewDetail from "../../../component/viewDetail/ViewDetail";

const ViewExternalUrl = () => {
  const state = useLocation();
  const data = state?.state?.urlData;

  const formatDateTime = (timestamp) => {
    if (!timestamp) return "";

    const date = new Date(timestamp).toLocaleDateString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const time = new Date(timestamp).toLocaleTimeString("en-IN", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // Ensure the time is formatted in 12-hour format
    });

    return `${date}, ${time}`;
  };

  const formattedCreatedDateTime = formatDateTime(data?.createdAt);

  // Format queryParams and headers to show key-value pairs
  const formatObject = (obj) => {
    if (!obj) return "No data available";
    return Object.entries(obj).map(([key, value]) => (
      <div key={key} style={{ marginBottom: "8px" }}>
        <strong>{key}:</strong> {value}
      </div>
    ));
  };

  const externalurlDetails = [
    { left: "Url Id", value: data ? data._id : null },
    { left: "Title", value: data ? data.title : null },
    {
      left: "Query Params",
      value: formatObject(data?.queryParams),
    },
    {
      left: "Headers",
      value: formatObject(data?.headers),
    },
    { left: "Base Url", value: data ? data.baseurl : null },
    { left: "External Url", value: data ? data.externalUrl : null },
    {
      left: "Created Date & Time",
      value: data ? formattedCreatedDateTime : null,
    },
  ];
  return (
    <MainLayout>
      <Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            Url Management
          </Typography>
        </Grid>
        <Grid style={{ marginLeft: "15px", marginBottom: "15px" }}>
          <Paper elevation={0}>
            <div className="icon">
              <Link to={"/urlmanagement"}>
                <img
                  src={arrowBack}
                  alt="arroWback"
                  style={{
                    width: "2.813rem",
                    height: "2.813rem",
                    cursor: "pointer",
                  }}
                />
              </Link>
            </div>
            <div className="ViewDeveloper-container">
              <h2>External Url Details</h2>
              <ViewDetail type="external-url" details={externalurlDetails} />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default ViewExternalUrl;
