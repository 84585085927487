import React, { useState } from "react";
import { Grid, styled, Box } from "@mui/material";
import ButtonComponent from "../Button";
import BasicDatePicker from "../DatePicker";
import SearchBar from "../SearchBar";
// import Dropdown from "../dropdown/Dropdown";

const Filter = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);



  const StyledBox = styled(Box)(({ theme }) => ({
    padding: "10px",
    borderRadius: "15px",
    backgroundColor: "rgba(0, 176, 80, 0.05)",
    "& .displayEnd": {
      display: "flex",
      justifyContent: "flex-end",
      backgroundColor: "rgba(0, 0, 0, 0.02)",
      paddingTop: "10px",
      columnGap: "8px",
      [theme.breakpoints.down("md")]: {
        columnGap: "0px",
      },
    },
    "& .MuiInputBase-root": {
      color: "#0B1426 !important",
      height: "44px",
      backgroundColor: "transparent",
    },
    "& .MuiOutlinedInput-input": {
      color: "#0B1426",
    },
  }));
  

  return (
    <StyledBox>
    <Grid container spacing={3} alignItems="flex-end">
    <Grid item xs={12} sm={6} md={4}>
          <SearchBar
            placeholder={"search by property name"}
            value={searchQuery}
            options={[]}
          />
        </Grid>

      <Grid item xs={12} sm={6} md={2}>
        <BasicDatePicker
          label="From Date"
    
          value={fromDate}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={2}>
        <BasicDatePicker
          label="To Date"
       
          value={toDate}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={2}>
        <ButtonComponent
          name="Reset Filter"
          borderRadius="100px"
          backgroundColor="rgba(0, 0, 0, 0.05)"
          color="black"
          width={"100%"}
          margin={"0px"}
          height={"45px"}
        />
      </Grid>
    </Grid>
  </StyledBox>
  );
};

export default Filter;



// import React, { useEffect, useState } from "react";
// import { Grid, styled, Box } from "@mui/material";
// import ButtonComponent from "../Button";
// import BasicDatePicker from "../DatePicker";
// import Dropdown from "../dropdown/Dropdown";
// import { getAPIHandler } from "../../config/service";

// const Filter = () => {
//   const [fromDate, setFromDate] = useState(null);
//   const [toDate, setToDate] = useState(null);
//   const [executives, setExecutives] = useState([]);
//   const [vicePresidents, setVicePresidents] = useState([]);
//   const [managers, setManagers] = useState([]);
//   const [properties, setProperties] = useState([]);
//   const [selectedExecutiveId, setSelectedExecutiveId] = useState("");
//   const [selectedVPId, setSelectedVPId] = useState("");
//   const [accountloading, setAccountLoading] = useState(false);

//   useEffect(() => {
//     fetchUserData();
//     fetchPropertyData();
//   }, []);

//   const fetchUserData = async () => {
//     try {
//       const response = await getAPIHandler({
//         endPoint: "listUserDetailsByType",
//       });
//       if (response.data && response.data.result && response.data.result.userDetails) {
//         const users = response.data.result.userDetails;

//         const executives = users.filter(user => user.userType === "EXECUTIVE");
//         const vicePresidents = users.filter(user => user.userType === "VP");
//         const managers = users.filter(user => user.userType === "MANAGER");

//         setExecutives(executives);
//         setVicePresidents(vicePresidents);
//         setManagers(managers);
//       } else {
//         console.error("No user data received.");
//       }
//     } catch (error) {
//       console.error("Error fetching user data:", error);
//     }
//   };

//   const fetchPropertyData = async () => {
//     try {
//       const propertyData = await getAPIHandler({
//         endPoint: "getAllProperty",
//       });

//       if (propertyData.data && propertyData.data.result && propertyData.data.result.property) {
//         const properties = propertyData.data.result.property;

//         const sortedProperties = properties.sort((a, b) => 
//           a.propertyName?.localeCompare(b.propertyName)
//         );

//         setProperties(sortedProperties);
//       } else {
//         console.error("No property data received.");
//       }
//     } catch (error) {
//       console.error("Error fetching property data:", error);
//     }
//   };

//   const handleSelect = async (selectedOption, type) => {
//     if (type === "executive" && selectedOption !== "Select Executive") {
//       const selectedExecutive = executives.find(
//         executive => executive.fullName === selectedOption
//       );
//       setSelectedExecutiveId(selectedExecutive ? selectedExecutive._id : "");
//     } else if (type === "vp" && selectedOption !== "Select Vice President") {
//       const selectedVP = vicePresidents.find(
//         vp => vp.fullName === selectedOption
//       );
//       setSelectedVPId(selectedVP ? selectedVP._id : "");
//     }
//   };

//   const StyledBox = styled(Box)(({ theme }) => ({
//     padding: "10px",
//     borderRadius: "15px",
//     backgroundColor: "rgba(0, 176, 80, 0.05)",
//     "& .displayEnd": {
//       display: "flex",
//       justifyContent: "flex-end",
//       backgroundColor: "rgba(0, 0, 0, 0.02)",
//       paddingTop: "10px",
//       columnGap: "8px",
//       [theme.breakpoints.down("md")]: {
//         columnGap: "0px",
//       },
//     },
//     "& .MuiInputBase-root": {
//       color: "#0B1426 !important",
//       height: "44px",
//       backgroundColor: "transparent",
//     },
//     "& .MuiOutlinedInput-input": {
//       color: "#0B1426",
//     },
//   }));

//   return (
//     <StyledBox>
//       <Grid container spacing={2}>
//         <Grid item xs={12}>
//           <Grid container spacing={4}>
//             <Grid item xs={12} sm={6} md={2}>
//             <Dropdown
//             options={["Select Project", ...properties.map(property => property.propertyName)]}
//             onSelect={(selectedOption) => handleSelect(selectedOption, "project")}
//           />
//             </Grid>
//             <Grid item xs={12} sm={6} md={2}>
//               <Dropdown options={["Select Source", "Facebook"]} onSelect={(selectedOption) => handleSelect(selectedOption, "source")} />
//             </Grid>
//             <Grid item xs={12} sm={6} md={2}>
//               <Dropdown
//                 options={["Select Vice President", ...vicePresidents.sort((a, b) => a.fullName?.localeCompare(b.fullName)).map(vp => vp.fullName)]}
//                 onSelect={(selectedOption) => handleSelect(selectedOption, "vp")}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6} md={2}>
//               <Dropdown
//                 options={["Select Manager", ...managers.sort((a, b) => a.fullName?.localeCompare(b.fullName)).map(manager => manager.fullName)]}
//                 onSelect={(selectedOption) => handleSelect(selectedOption, "manager")}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6} md={2}>
//               <Dropdown
//                 options={["Select Executive", ...executives.sort((a, b) => a.fullName?.localeCompare(b.fullName)).map(executive => executive.fullName)]}
//                 onSelect={(selectedOption) => handleSelect(selectedOption, "executive")}
//               />
//             </Grid>
//           </Grid>
//         </Grid>

//         <Grid item xs={12}>
//           <Grid container spacing={2}>
//             <Grid item xs={12} sm={6} md={2}>
//               <BasicDatePicker label="From Date" value={fromDate} onChange={(date) => setFromDate(date)} />
//             </Grid>
//             <Grid item xs={12} sm={6} md={2}>
//               <BasicDatePicker label="To Date" value={toDate} onChange={(date) => setToDate(date)} />
//             </Grid>
//             <Grid item xs={12} sm={6} md={2}>
//               <ButtonComponent
//                 name="Reset Filter"
//                 borderRadius="100px"
//                 backgroundColor="rgba(0, 0, 0, 0.05)"
//                 color="black"
//                 width={"100%"}
//                 margin={"0px"}
//                 height={"41px"}
//               />
//             </Grid>
//           </Grid>
//         </Grid>
//       </Grid>
//     </StyledBox>
//   );
// };

// export default Filter;
