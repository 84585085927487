import React, { useState } from "react";
import MainLayout from "../../layout/MainLayout";
import CustomizedTables from "../../component/Table/ExecutiveManagementData";
import { Grid, Typography, Paper } from "@mui/material";
import ButtonComponent from "../../component/Button";
import Filter from "../../component/Filters/Filter";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { postAPIHandlerForFile } from "../../config/service";

const ExecutiveManagement = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const handleDownloadCSV = () => {
    const downloadFunction = document.getElementById(
      "executive-tablesdata-download"
    );
    if (downloadFunction) {
      downloadFunction.click();
    }
  };

  let downloadCSVFunction = null;

  const triggerFileInput = () => {
    document.getElementById("bulk-upload").click();
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      toast.error("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    setIsLoading(true);

    try {
      const response = await postAPIHandlerForFile({
        endPoint: "userRegistrationUsingFile",
        dataToSend: formData,
      });

      if (response.status === 200) {
        toast.success("Executive registration successfull..");
      } else {
        toast.error("User already exists.");
      }
    } catch (error) {
      console.error("Error during file upload:", error);
      toast.error("An error occurred while uploading the file.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MainLayout>
      <Grid
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: "20px",
        }}
      >
        <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
          Executive Management
        </Typography>
        <Grid>
          <ButtonComponent
            name={"Download CSV"}
            borderRadius={"100px"}
            backgroundColor={"rgba(0, 0, 0, 0.05)"}
            onClick={handleDownloadCSV}
            color={"black"}
          />
          <input
            type="file"
            accept=".xlsx"
            onChange={handleFileChange} // Upload after file is selected
            style={{ display: "none" }}
            id="bulk-upload"
          />
          <ButtonComponent
            name={"Upload In Bulk"}
            borderRadius={"100px"}
            onClick={triggerFileInput} // Trigger file input when clicked
            disabled={isLoading} // Disable button while loading
          />
          <ButtonComponent
            name={"+Add Executive"}
            borderRadius={"100px"}
            onClick={() => navigate("/executivemanagement/addexecutive")}
          />
        </Grid>
      </Grid>

      <Grid style={{ padding: "15px" }}>
        <Filter
          searchPlaceholder={"Search by executive name"}
          onSearch={handleSearch}
          onFromDateChange={handleFromDateChange}
          onToDateChange={handleToDateChange}
        />
      </Grid>

      <Grid style={{ padding: "15px" }}>
        <CustomizedTables
          searchQuery={searchQuery}
          fromDate={fromDate}
          toDate={toDate}
          setDownloadCSVFunction={(func) => {
            downloadCSVFunction = func;
          }}
        />
      </Grid>
    </MainLayout>
  );
};

export default ExecutiveManagement;
