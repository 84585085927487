import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
} from "@mui/material";

const PaginationTableStyle = {
  rowStyle: {
    backgroundColor: "rgb(0, 176, 80)",
    color: "white",
    flex: "1 1 auto",
    padding: 8,
  },

  tableContainer: {
    overflowX: "auto",
  },

  tableHeaderCell: {
    color: "white",
    fontSize: "16px",
    fontWeight: 500,
    textAlign: "center",
  },

  tableCell: {
    flex: "1 1 auto",
    padding: 8,
    textAlign: "center",
  },
};

const PaginationTable = ({ columns, data, rowsPerPageOptions }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper>
      <TableContainer
        style={PaginationTableStyle.tableContainer}
        component={Paper}
        sx={{
          borderRadius: "15px",  boxShadow: "0 0 0px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Table>
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-root": {
                  padding: "11px",
                },
              }}
              style={PaginationTableStyle.rowStyle}
            >
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={PaginationTableStyle.tableHeaderCell}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& .MuiTableCell-root": {
                padding: "11px",
              },
            }}
          >
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      style={PaginationTableStyle.tableCell}
                    >
                      {column.id === "action" ? (
                        <Box className="row-button-container">
                          {row[column.id]}
                        </Box>
                      ) : (
                        row[column.id]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length > rowsPerPage && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

PaginationTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
};

PaginationTable.defaultProps = {
  rowsPerPageOptions: [10, 25],
};

export default PaginationTable;
