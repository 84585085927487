import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import MainLayout from "../../../layout/MainLayout";
import { Paper, Grid, Typography,TextField } from "@mui/material";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import "./AddProject.css";
import ButtonComponent from "../../../component/Button";
import { Link } from "react-router-dom";

const AddProject = () => {
  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    roleType: Yup.string().required("Please select role type."),
    memeberType: Yup.string().required("Please select memeber type."),
    firstName: Yup.string().required("Please enter first name."),
    lastName: Yup.string().required("Please enter last name."),
    email: Yup.string()
      .email("please provide valid email address.")
      .required("Please enter email address."),
    phoneNumber: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits.")
      .required("Please enter phone number."),
    state: Yup.string().required("Please enter state."),
    city: Yup.string().required("Please enter city."),
    address: Yup.string().required("Please enter address."),
    vp: Yup.string().required("Please select vp."),
    project: Yup.string().required("Please add project."),
    manager: Yup.string().required("Please select manager."),
  });

  return (
    <MainLayout>
      <Grid>
        <Grid
          style={{
            display: "flex",

            alignItems: "center",
            padding: "1rem",
          }}
        >
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            Executive Management
          </Typography>
        </Grid>
        <Grid style={{ marginLeft: "15px", marginBottom: "15px" }}>
          <Paper
            elevation={0}
            style={{
              padding: "15px",
              overflowY: "auto",
              scrollbarWidth: "none",
            }}
          >
            <div>
              <div className="arrow">
                <ArrowBackTwoToneIcon />
                <h2>Add Executive</h2>
              </div>
            </div>
            <div className="member">
              <h4>Member Details</h4>
            </div>
            <div className="Addexecutive-container">
              <Formik
                initialValues={{
                  roleType: "",
                  memeberType: "",
                  firstName: "",
                  lastName: "",
                  email: "",
                  phoneNumber: "",
                  state: "",
                  city: "",
                  address: "",
                  vp: "",
                  project: "",
                  manager: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    setSubmitting(false);
                  }, 400);
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="form-group">
                      <div className="role-type">
                        <label>
                          <strong>Role Type</strong>
                        </label>

                        <label>
                          <Field type="radio" name="roleType" value="sales" />
                          Sales
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="roleType"
                            value="presales"
                          />
                          Presales
                        </label>
                        <label>
                          <Field type="radio" name="roleType" value="Both" />
                          Both
                        </label>
                      </div>
                      <ErrorMessage
                        name="roleType"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="form-group">
                      <div className="role-type">
                        <label>
                          <strong>Memeber Type</strong>
                        </label>

                        <label>
                          <Field
                            type="radio"
                            name="memeberType"
                            value="internal"
                          />
                          Internal
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="memeberType"
                            value="external"
                          />
                          External
                        </label>
                      </div>
                      <ErrorMessage
                        name="memeberType"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="form-group">
                      <Field
                        as={Input}
                        type="text"
                        name="firstName"
                        placeholder="Enter first name"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="form-group">
                      <Field
                        as={Input}
                        type="text"
                        name="lastName"
                        placeholder="Enter last name"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="form-group">
                      <Field
                        as={Input}
                        type="email"
                        name="email"
                        placeholder="Enter email address"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="form-group">
                      <Field
                        as={Input}
                        type="tel"
                        name="phoneNumber"
                        placeholder="Enter phone number"
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="form-group">
                      <Field as={Dropdown} name="state">
                        <MenuButton style={{ width: "100%" }}>
                          Select state
                        </MenuButton>
                        <Menu style={{ width: "35rem" }}>
                          <MenuItem>Add item</MenuItem>
                        </Menu>
                      </Field>
                      <ErrorMessage
                        name="state"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="form-group">
                      <Field as={Dropdown} name="state">
                        <MenuButton style={{ width: "100%" }}>
                          Select City
                        </MenuButton>
                        <Menu style={{ width: "35rem" }}>
                          <MenuItem>Add item</MenuItem>
                        </Menu>
                      </Field>
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="form-group">
                      <Field
                        as={Textarea}
                        name="address"
                        placeholder="Address"
                      />
                      <ErrorMessage
                        name="address"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="form-group">
                      <Field as={Dropdown} name="vp">
                        <MenuButton style={{ width: "100%" }}>
                          Select VP
                        </MenuButton>
                        <Menu style={{ width: "35rem" }}>
                          <MenuItem>Add item</MenuItem>
                        </Menu>
                      </Field>
                      <ErrorMessage
                        name="vp"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="form-group">
                      <Field as={Dropdown} name="manager">
                        <MenuButton style={{ width: "100%" }}>
                          Select Manager
                        </MenuButton>
                        <Menu style={{ width: "35rem" }}>
                          <MenuItem>Add item</MenuItem>
                        </Menu>
                      </Field>
                      <ErrorMessage
                        name="manager"
                        component="div"
                        className="error"
                      />
                    </div>

                    <label style={{ padding: "1rem" }}>
                      <strong>Project List</strong>
                    </label>

                    <div className="form-group">
                      <Field
                        as={TextField}
                        placeholder="ASDS Property"
                      />
                      <ErrorMessage
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="form-group">
                      <Field as={Dropdown} name="project">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <MenuButton style={{ width: "100%" }}>
                            Add Project
                          </MenuButton>
                        </div>
                        <Menu style={{ width: "35rem" }}>
                          <MenuItem>Add item</MenuItem>
                        </Menu>
                      </Field>

                      <ErrorMessage
                        name="project"
                        component="div"
                        className="error"
                      />
                      <div className="more-projects">
                        <Link>Assign More Project</Link>
                      </div>
                    </div>

                    <ButtonComponent name={"Clear"} />
                    <ButtonComponent
                      type="submit"
                      name={"Add member"}
                      disabled={isSubmitting}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default AddProject;
