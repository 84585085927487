import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import MainLayout from "../../../layout/MainLayout";
import {
  Paper,
  Box,
  Typography,
  IconButton,
  Grid,
  Button,
} from "@mui/material";
import arrowBack from "../../../assets/arrowBack.svg";
import { Link, useNavigate } from "react-router-dom";
import { postAPIHandler } from "../../../config/service";
import { externalUrlSchema } from "../../../schemas";
import toast from "react-hot-toast";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonComponent from "../../../component/Button";
import Input from "@mui/joy/Input";

const AddExternalUrl = () => {
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [showQueryParamFields, setShowQueryParamFields] = useState(false);
  const [showHeaderFields, setShowHeaderFields] = useState(false);

  const handleAddItem = (list, setList, key, value) => {
    if (key && value) {
      setList([...list, { key, value }]);
    }
  };

  const handleRemoveItem = (list, setList, index) => {
    setList(list.filter((_, idx) => idx !== index));
  };

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const dataToSend = {
        title: values.title,
        baseUrl: values.baseUrl,
        queryParams,
        headers,
      };

      const response = await postAPIHandler({
        endPoint: "addexternalUrl",
        dataToSend,
      });

      if (response && response.data.responseCode === 200) {
        toast.success("External URL added successfully.");
        navigate("/urlmanagement");
      } else {
        toast.error("Error in adding external URL.");
      }
    } catch (error) {
      console.error("Error:", error);
      setSubmitting(false);
    }
  };

  return (
    <MainLayout>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" padding="1rem">
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            URL Management
          </Typography>
        </Box>
        <Box sx={{ padding: "15px", overflowY: "auto" }}>
          <Box display="flex" alignItems="center" marginBottom={4}>
            <Link to="/urlmanagement">
              <img
                src={arrowBack}
                alt="Back"
                style={{ width: 40, height: 40, cursor: "pointer" }}
              />
            </Link>
            <Typography variant="h5" fontWeight="bold" marginLeft={2}>
              Add External URL
            </Typography>
          </Box>
          <Paper elevation={1} sx={{ padding: 4, borderRadius: "10px" }}>
            <Formik
              initialValues={{ title: "", baseUrl: "" }}
              validationSchema={externalUrlSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h6" fontWeight="bold">
                        General Information
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Field
                        as={Input}
                        type="text"
                        name="title"
                        placeholder="Enter Title"
                        fullWidth
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="error"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        as={Input}
                        type="text"
                        name="baseUrl"
                        placeholder="Enter Base Url"
                        fullWidth
                      />
                      <ErrorMessage
                        name="baseUrl"
                        component="div"
                        className="error"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="h6" fontWeight="bold">
                        Headers
                      </Typography>
                    </Grid>
                    <Grid item xs={12} display="flex" alignItems="center">
                      <IconButton
                        color="primary"
                        onClick={() => setShowHeaderFields(!showHeaderFields)}
                      >
                        <AddIcon />
                      </IconButton>
                      <Typography variant="body1" marginLeft={2}>
                        Header
                      </Typography>
                    </Grid>
                    {showHeaderFields && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <Field
                            as={Input}
                            name="headerKey"
                            type="text"
                            fullWidth
                            placeholder="Enter Header Key"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            as={Input}
                            name="headerValue"
                            Type="text"
                            placeholder="Enter Header Value"
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", justifyContent: "end" }}
                        >
                          <Button
                            variant="outlined"
                            color="success"
                            onClick={() => {
                              handleAddItem(
                                headers,
                                setHeaders,
                                values.headerKey,
                                values.headerValue
                              );
                              setFieldValue("headerKey", "");
                              setFieldValue("headerValue", "");
                            }}
                          >
                            + Header
                          </Button>
                        </Grid>
                      </>
                    )}
                    {headers.length > 0 && (
                      <Grid item xs={12}>
                        {headers.map((header, index) => (
                          <Box
                            key={index}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            marginBottom={1}
                          >
                            <Typography>
                              {header.key}: {header.value}
                            </Typography>
                            <IconButton
                              color="error"
                              onClick={() =>
                                handleRemoveItem(headers, setHeaders, index)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        ))}
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <Typography variant="h6" fontWeight="bold">
                        Query Parameters
                      </Typography>
                    </Grid>
                    <Grid item xs={12} display="flex" alignItems="center">
                      <IconButton
                        color="primary"
                        onClick={() =>
                          setShowQueryParamFields(!showQueryParamFields)
                        }
                      >
                        <AddIcon />
                      </IconButton>
                      <Typography variant="body1" marginLeft={2}>
                        Query Parameter
                      </Typography>
                    </Grid>
                    {showQueryParamFields && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <Field
                            as={Input}
                            type="text"
                            name="queryParamKey"
                            placeholder="Enter Query Parameter Key"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            as={Input}
                            type="text"
                            name="queryParamValue"
                            placeholder="Enter Query Parameter Value"
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", justifyContent: "end" }}
                        >
                          <Button
                            variant="outlined"
                            color="success"
                            onClick={() => {
                              handleAddItem(
                                queryParams,
                                setQueryParams,
                                values.queryParamKey,
                                values.queryParamValue
                              );
                              setFieldValue("queryParamKey", "");
                              setFieldValue("queryParamValue", "");
                            }}
                          >
                            + Query Parameter{" "}
                          </Button>
                        </Grid>
                      </>
                    )}
                    {queryParams.length > 0 && (
                      <Grid item xs={12}>
                        {queryParams.map((param, index) => (
                          <Box
                            key={index}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            marginBottom={1}
                          >
                            <Typography>
                              {param.key}: {param.value}
                            </Typography>
                            <IconButton
                              color="error"
                              onClick={() =>
                                handleRemoveItem(
                                  queryParams,
                                  setQueryParams,
                                  index
                                )
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        ))}
                      </Grid>
                    )}

                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ButtonComponent
                        type="submitL"
                        height="35px"
                        name="Add URL"
                        disabled={isSubmitting}
                      />
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default AddExternalUrl;
