import React, { useEffect, useState } from "react";
import TotalNumCard from "../../component/total_num_card/TotalNumCard";
import Dropdown from "../../component/dropdown/Dropdown";
import ExecutiveAnalysisCard from "../../component/executive_analysis_card/ExecutiveAnalysisCard";
import TotalMembers from "../../assets/TotalMembers.png";
import TotalRequests from "../../assets/TotalRequests.png";
import TotalGeneratedLeads from "../../assets/TotalGeneratedLeads.png";
import TotalProperty from "../../assets/TotalProperty.png";
import TotalBookedVisits from "../../assets/TotalBookedVisits.png";
import TotalConfirmedBookings from "../../assets/TotalConfirmedBookings.png";
import AssignedProperty from "../../assets/AssignedProperty.png";
import RequestedLeads from "../../assets/RequestedLeads.png";
import GeneratedLeads from "../../assets/GeneratedLeads.png";
import BookedVisits from "../../assets/BookedVisits.png";
import ConfirmedBookings from "../../assets/ConfirmedBookigs.png";
import "./Dashboard.css";
import { Grid, Typography, Box, styled } from "@mui/material";
import BarGraph from "../../component/bar_graph/BarGraph";
import AlternateLayout from "../../layout/AlternateLayout";
import DatePicker from "../../component/DatePicker";
import { getAPIHandler } from "../../config/service";
import { useNavigate } from "react-router-dom";


const CardGrid = styled(Grid)(({ theme }) => ({
  padding: "20px",
  "@media(max-width: 400px)": {
    padding: "10px",
  },
}));

const FirstBoxDashboard = styled(Box)(({ theme }) => ({
  // background: "rgba(235,237,239)",
  padding: "10px",
  borderRadius: "5px",
}));

const Dashboard = () => {
  const [totalmembers, setTotalMembers] = useState(0);
  const [totalProperties, setTotalProperties] = useState(0);
  const [totalRequests, setTotalRequests] = useState(0);
  const [totalGeneratedLeads, setTotalGeneratedLeads] = useState(0);
  const [totalBookedVisits, setTotalBookedVisits] = useState(0);
  const [totalConfirmedBookings, setTotalConfirmVisits] = useState(0);
  const [totalExecutives, setTotalExecutives] = useState([]);
  const [selectedExecutiveId, setSelectedExecutiveId] = useState("");
  const [executiveDetails, setExecutiveDetails] = useState([]);
  const [leads, setLeads] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedYear, setSelectedYear] = useState("2024");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const [executiveData, setExecutiveData] = useState({
    totalAssignedProperty: 0,
    totalBookedVisit: 0,
    totalConfirmedBooking: 0,
    totalGeneratedLead: 0,
    totalRequestedLead: 0,
  });
  const [totalVicePresidents, setTotalVicePresidents] = useState(0);
  const [totalManagers, setTotalManagers] = useState(0);
  const [totalExecutivesCount, setTotalExecutivesCount] = useState(0);
  const Navigate = useNavigate();

  const handleDateChange = (date) => {
    setFromDate(date);
    setToDate(date);
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  const handleLocationChange = (location) => {
    setSelectedLocation(location);
  };

  const handleSelect = async (selectedOption) => {
    if (selectedOption === "Select Executive") {
      setSelectedExecutiveId("");
      return;
    }
    const selectedExecutive = executiveDetails.find(
      (executive) => executive.fullName === selectedOption
    );

    if (selectedExecutive) {
      setSelectedExecutiveId(selectedExecutive.id);
      await fetchAllLeadsByExecutive(selectedExecutive.id);
    } else {
      console.error("Selected executive not found.");
    }
  };

  const handleSourceChange = (source) => setSelectedSource(source);

  const handleCardClick = () => {
    Navigate("/vpmanagement");
  };
  const handleCardClickToLead = () => {
    Navigate("/leadsmanagement");
  };
  const handleCardClickForManager = () => {
    Navigate("/managermanagement");
  };
  const handleCardClickForExecutive = () => {
    Navigate("/executivemanagement");
  };
  const handleCardClickToProperty = () => {
    Navigate("/propertymanagement");
  };

  useEffect(() => {
    fetchUserData();
    fetchPropertyData();
    fetchLeads();
    fetchAllLeadsByExecutive();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "listUserDetailsByType",
      });
      if (
        response.data &&
        response.data.result &&
        response.data.result.userDetails
      ) {
        const users = response.data.result.userDetails;

        const executives = users.filter(
          (user) => user.userType === "EXECUTIVE"
        );
        const vicePresidents = users.filter((user) => user.userType === "VP");
        const managers = users.filter((user) => user.userType === "MANAGER");

        const executiveDetails = executives.map((executive) => ({
          fullName: executive.fullName,
          id: executive._id,
        }));

        const executiveNames = executiveDetails.map((data) => data.fullName);

        const vicePresidentDetails = vicePresidents.map((vp) => ({
          fullName: vp.fullName,
          id: vp._id,
        }));

        const managerDetails = managers.map((manager) => ({
          fullName: manager.fullName,
          id: manager._id,
        }));

        setTotalExecutives(executiveNames);
        setTotalMembers(executiveDetails.length);
        setTotalExecutivesCount(executiveDetails.length);
        setExecutiveDetails(executiveDetails);
        setTotalVicePresidents(vicePresidentDetails.length);
        setTotalManagers(managerDetails.length);
      } else {
        console.error("No user data received.");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  const fetchPropertyData = async () => {
    try {
      const propertyData = await getAPIHandler({
        endPoint: "getAllProperty",
      });

      if (propertyData.data.result.property) {
        const properties = propertyData.data.result.property;
        const propertyCount = properties.length;

        setTotalProperties(propertyCount);
      } else {
        console.error(
          "No property data received or invalid response structure."
        );
      }
    } catch (error) {
      console.error("Error fetching property data:", error);
    }
  };
  const fetchLeads = async () => {
    try {
      const otherLeadsResponse = await getAPIHandler({
        endPoint: "getAllLeads",
      });
      if (
        otherLeadsResponse.data &&
        otherLeadsResponse.data.result &&
        otherLeadsResponse.data.result.allLeads
      ) {
        const otherLeadsData = otherLeadsResponse.data.result.allLeads;
        setLeads(otherLeadsData);
        calculateLeadCounts(otherLeadsData);
        console.log(
          "Unexpected response structure for other leads:",
          otherLeadsResponse
        );
      } else {
        console.error(
          "Unexpected response structure for other leads:",
          otherLeadsResponse.data
        );
      }

      const facebookResponse = await getAPIHandler({
        endPoint: "getLeadsFromFacebook",
      });
      if (facebookResponse.data && facebookResponse.data.result) {
        const facebookLeadsData = facebookResponse.data.result;
        const totalRequests = facebookLeadsData.reduce((total, property) => {
          if (
            property.PropertyDetails &&
            property.PropertyDetails.generatedLeads
          ) {
            console.log(
              "Generated Leads Length for this Property:",
              property.PropertyDetails.generatedLeads.length
            );
            return total + property.PropertyDetails.generatedLeads.length;
          }
          return total;
        }, 0);

        setTotalRequests(totalRequests);
      } else {
        console.error(
          "Unexpected response structure for Facebook leads:",
          facebookResponse.data
        );
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };
  const fetchAllLeadsByExecutive = async (executiveId) => {
    try {
      const response = await getAPIHandler({
        endPoint: "getAllLeadsByExecutive",
        paramsData: { executiveId, fromDate, toDate },
      });
      if (response.data && response.data.result) {
        setExecutiveData(response.data.result);
      }
    } catch (error) {
      console.error("Error fetching leads by executive:", error);
    }
  };
  const calculateLeadCounts = (leadsData) => {
    let totalGeneratedLeads = 0;
    let totalBookedVisits = 0;
    let totalConfirmedBookings = 0;

    leadsData.forEach((lead) => {
      totalGeneratedLeads += 1;
      if (lead.leadStatus === "BOOKEDVISIT") {
        totalBookedVisits += 1;
      } else if (
        lead.leadStatus === "BOOKEDVISIT" &&
        lead.visitStatus === "ACCEPTED"
      ) {
        totalConfirmedBookings += 1;
      }
    });

    setTotalGeneratedLeads(totalGeneratedLeads);
    setTotalBookedVisits(totalBookedVisits);
    setTotalConfirmVisits(totalConfirmedBookings);
  };

  const userData = JSON.parse(sessionStorage.getItem("user"));

  const { userType } = userData || {};

  return (
    <AlternateLayout>
      {/*<div
        className="dashboard-dropdown-content"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "10px",
        }}
      >
        <strong>Time Period</strong>
        <DatePicker onDateChange={handleDateChange} label={"From Date"} />
        <DatePicker onDateChange={handleDateChange} label={"To Date"} />
      </div>*/}

      <FirstBoxDashboard>
        <Grid container spacing={1}>
          {userType === "ADMIN" && (
            <CardGrid item xs={12} sm={6} md={4}>
              <TotalNumCard
                imageUrl={TotalMembers}
                heading="Total Vice Presidents"
                number={
                  totalVicePresidents !== 0 ? (
                    totalVicePresidents
                  ) : (
                    <div className="loader"></div>
                  )
                }
                onClick={handleCardClick}
              />
            </CardGrid>
          )}

          <CardGrid item xs={12} sm={6} md={4}>
            <TotalNumCard
              imageUrl={TotalMembers}
              heading="Total Managers"
              number={
                totalManagers !== 0 ? (
                  totalManagers
                ) : (
                  <div className="loader"></div>
                )
              }
              onClick={handleCardClickForManager}
            />
          </CardGrid>

          <CardGrid item xs={12} sm={6} md={4}>
            <TotalNumCard
              imageUrl={TotalMembers}
              heading="Total Executives"
              number={
                totalmembers !== 0 ? (
                  totalmembers
                ) : (
                  <div className="loader"></div>
                )
              }
              onClick={handleCardClickForExecutive}
            />
          </CardGrid>

          <CardGrid item xs={12} sm={6} md={4}>
            <TotalNumCard
              imageUrl={TotalRequests}
              heading="Total Requested Leads"
              number={
                totalRequests !== 0 ? (
                  totalRequests
                ) : (
                  <div className="loader"></div>
                )
              }
              onClick={handleCardClickToLead}
            />
          </CardGrid>

          <CardGrid item xs={12} sm={6} md={4}>
            <TotalNumCard
              imageUrl={TotalGeneratedLeads}
              heading="Total Generated Leads"
              number={
                totalGeneratedLeads !== 0 ? (
                  totalGeneratedLeads
                ) : (
                  <div className="loader"></div>
                )
              }
              onClick={handleCardClickToLead}
            />
          </CardGrid>

          <CardGrid item xs={12} sm={6} md={4}>
            <TotalNumCard
              imageUrl={TotalProperty}
              heading="Total Properties"
              number={
                totalProperties !== 0 ? (
                  totalProperties
                ) : (
                  <div className="loader"></div>
                )
              }
              onClick={handleCardClickToProperty}
            />
          </CardGrid>

          <CardGrid item xs={12} sm={6} md={4}>
            <TotalNumCard
              imageUrl={TotalBookedVisits}
              heading="Total Booked Visits"
              number={
                totalBookedVisits !== undefined ? (
                  totalBookedVisits
                ) : (
                  <div className="loader"></div>
                )
              }
              onClick={handleCardClickToLead}
            />
          </CardGrid>

          <CardGrid item xs={12} sm={6} md={4}>
            <TotalNumCard
              imageUrl={TotalConfirmedBookings}
              heading="Total Confirmed Bookings"
              number={
                totalConfirmedBookings !== undefined ? (
                  totalConfirmedBookings
                ) : (
                  <div className="loader"></div>
                )
              }
              onClick={handleCardClickToLead}
            />
          </CardGrid>
        </Grid>
      </FirstBoxDashboard>

      <div className="dashboard-leads-analysis-div">
        <p>Leads Analysis</p>
      </div>

      <div className="dashboard-graph-div">
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 2,
          }}
        >
          Number of Leads by Month (Year : {parseInt(selectedYear)})
        </Typography>

        <div
          className="dashboard-dropdown-content dashboard-dropdown-content2"
          style={{
            display: "flex",
            alignItems: "space-evenly",
            justifyContent: "center",
          }}
        >
          {" "}
          <Dropdown
            options={["Select Source", "Facebook", "99 acres"]}
            onSelect={handleSourceChange}
            width={"209px"}
          />
          <Dropdown
            options={["All Locations", "Delhi", "Chennai"]}
            onSelect={handleLocationChange}
            width={"209px"}
          />
          <Dropdown
            options={["2024", "2025"]}
            onSelect={handleYearChange}
            width={"209px"}
          />
        </div>

        <div>
          <BarGraph
            selectedYear={parseInt(selectedYear)}
            selectedLocation={selectedLocation}
            selectedSource={selectedSource}
          />
        </div>
      </div>

      <div className="dashboard-executive-analysis-div">
        <p>Executive Analysis</p>
      </div>

      <div className="dashboard-executive-container">

        <div
          className="dashboard-dropdown-container"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="dashboard-dropdown-content dashboard-dropdown-content1">
            <strong>Team Member</strong>
            <Dropdown
              options={[
                "Select Executive",
                ...totalExecutives.sort((a, b) => a.localeCompare(b)),
              ]}
              onSelect={handleSelect}
              width={"209px"}
            />
          </div>
          <div className="dashboard-dropdown-content dashboard-dropdown-content2">
            <strong>Time Period</strong>
            <DatePicker onDateChange={handleDateChange} label={"From Date"} />
            <DatePicker onDateChange={handleDateChange} label={"To Date"} />
          </div>
        </div>

        <FirstBoxDashboard>
          <Grid container spacing={1}>
            <CardGrid item xs={12} sm={6} md={4}>
              <ExecutiveAnalysisCard
                backgroundImage={AssignedProperty}
                heading="Total Assigned Property"
                count={executiveData.totalAssignedProperty}
              />
            </CardGrid>
            <CardGrid item xs={12} sm={6} md={4}>
              <ExecutiveAnalysisCard
                backgroundImage={RequestedLeads}
                heading="Total Requested Leads"
                count={executiveData.totalRequestedLead}
              />
            </CardGrid>
            <CardGrid item xs={12} sm={6} md={4}>
              <ExecutiveAnalysisCard
                backgroundImage={GeneratedLeads}
                heading="Total Generated Leads"
                count={executiveData.totalGeneratedLead}
              />
            </CardGrid>
            <CardGrid item xs={12} sm={6} md={4}>
              <ExecutiveAnalysisCard
                backgroundImage={BookedVisits}
                heading="Total Booked Visits"
                count={executiveData.totalBookedVisit}
              />
            </CardGrid>
            <CardGrid item xs={12} sm={6} md={4}>
              <ExecutiveAnalysisCard
                backgroundImage={ConfirmedBookings}
                heading="Total Confirmed Bookings"
                count={executiveData.totalConfirmedBooking}
              />
            </CardGrid>
          </Grid>
        </FirstBoxDashboard>
      </div>
    </AlternateLayout>
  );
};

export default Dashboard;
