import React from 'react';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function SearchBar({ value, options, placeholder, onSearch }) {
  

  
  return (
    <Stack>
      <Autocomplete
        id="free-solo-2-demo"
        freeSolo
        disableClearable
        options={options}
        value={value}
        onInputChange={onSearch}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              type: 'search',
              className: 'MuiOutlinedInput-root',
              sx: { 
                borderRadius: '100px', 
                height: '3rem',
              },
              endAdornment: (
                <React.Fragment>
                 
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Stack>
  );
}
