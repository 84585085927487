import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, Pagination, Tooltip, CircularProgress, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LogoutPopup from "../../component/Popup/Logoutpopup";
import { IoEyeOutline } from "react-icons/io5";
import { BiEditAlt } from "react-icons/bi";
import { BiBlock } from "react-icons/bi";
import { getAPIHandler } from "../../config/service";
import { saveAs } from "file-saver";
import { format } from "date-fns";
import DataNotFound from "../DataNotFound";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(0, 176, 80)",
    color: theme.palette.common.white,
    fontSize: "1rem",
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(even)": {
    backgroundColor: "rgba(0, 176, 80, 0.05)",
  },
  "&:last-child td, &:last-child th": {},
  "& td ": {},
}));
export default function CustomizedTables(props) {
  const { searchQuery, fromDate, toDate, setDownloadCSVFunction } = props;
  const [filter, setFilter] = useState({ page: 1, limit: 10 });
  const [totalPages, setTotalPages] = useState(0);
  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = React.useState(false);
  const [selectedUserId, setSelectedUserId] = React.useState(null);
  const [selectedUserStatus, setSelectedUserStatus] = React.useState(null);
  const [isDataLoading, setisDataLoading] = useState(true);
  const [data, setData] = React.useState([]);
  const navigate = useNavigate();

  const handleLogoutClick = (id, status) => {
    setIsLogoutPopupOpen(true);
    setSelectedUserId(id);
    setSelectedUserStatus(status);
  };

  const handleLogoutConfirm = () => {
    setIsLogoutPopupOpen(false);
    setSelectedUserId(null);
  };

  const handleChangePage = (event, value) => {
    setFilter({ ...filter, page: value });
  };

  const handleViewClick = (vpId) => {
    navigate(`/vpmanagement/vpdetails/${vpId}`);
  };

  const handleEditClick = (vpId) => {
    navigate(`/vpmanagement/editvp/${vpId}`);
  };

  useEffect(() => {
    setDownloadCSVFunction(() => handleDownloadCSV);
  }, [setDownloadCSVFunction]);

  const getData = async () => {
    setisDataLoading(true);
    try {
      const formattedFromDate = fromDate
        ? format(new Date(fromDate), "yyyy-MM-dd")
        : undefined;
      const formattedToDate = toDate
        ? format(new Date(toDate), "yyyy-MM-dd")
        : undefined;

      const paramsData = {
        ...filter,
        name: searchQuery || undefined,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        userType: "VP",
      };

      const response = await getAPIHandler({
        endPoint: "listUserDetailsByType",
        paramsData,
      });
      const vpData = response.data.result.userDetails;

      const manaCountPromises = vpData.map((manager) =>
        getAPIHandler({
          endPoint: "listManagersByVP",
          paramsData: { vpId: manager._id },
        })
      );

      const manaCountResponses = await Promise.all(manaCountPromises);
      console.log("Manager Counts:", manaCountResponses);

      const execCountPromises = vpData.map((executive) =>
        getAPIHandler({
          endPoint: "listExecutivesByManagerOrVP",
          paramsData: { vpId: executive._id },
        })
      );

      const execCountResponses = await Promise.all(execCountPromises);
      console.log("Executive Counts:", execCountResponses);

      const manaCounts = manaCountResponses.map((response) => {
        const managerDetails = response.data?.result?.userDetails || [];
        return managerDetails.length;
      });

      const execCounts = execCountResponses.map((response) => {
        const executiveDetails = response.data?.result?.executiveDetails || [];
        return executiveDetails.length;
      });

      const mergedData = vpData.map((vp, index) => ({
        ...vp,
        NoOfAssignedManager: manaCounts[index],
        NoOfAssignedExecutive: execCounts[index],
      }));

      setData(mergedData);
      setTotalPages(response.data.result.totalPages || 0);
      setisDataLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getData();
  }, [filter, searchQuery, fromDate, toDate]);

  const renderCellWithTooltip = (content, limit) => {
    if (content.length > limit) {
      return (
        <Tooltip title={content}>
          <span>{`${content.substring(0, limit)}...`}</span>
        </Tooltip>
      );
    }
    return content;
  };

  const handleDownloadCSV = () => {
    const headers = [
      "S.No.",
      "Id",
      "Name",
      "Mobile no.",
      "Email",
      "Number of Assigened Manager",
      "Number of Assigened Executive",
      "Created Date & Time",
      "Status",
    ];

    const csvContent = [
      headers.join(","),
      ...data.map((row, index) =>
        [
          index + 1 + (filter.page - 1) * filter.limit,
          row._id,
          row.fullName,
          row.mobileNumber,
          row.email,
          row.NoOfAssignedManager,
          row.NoOfAssignedExecutive,
          new Date(row.createdAt).toLocaleString("en-IN", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "Asia/Kolkata",
          }),
          row.status,
        ].join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const timestamp = format(new Date(), "yyyy-MM-dd_HH:mm");
    saveAs(blob, `vp_list_${timestamp}.csv`);
  };

  const createRowsFromVPManagementData = (data) => {
    return data.map((management, index) => {
      const lastSixDigits = management._id.slice(-6);
      return {
        index: index + 1,
        _id: management._id,
        vpId: lastSixDigits,
        fullName: management.fullName,
        mobileNumber: management.mobileNumber,
        email: management.email,
        NoOfAssignedManager: management.NoOfAssignedManager,
        NoOfAssignedExecutive: management.NoOfAssignedExecutive,
        createdAt: new Date(management.createdAt)
          .toLocaleString("en-IN", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "Asia/Kolkata",
          })
          .replace(",", ""),
        status: management.status,
      };
    });
  };

  const rows = data.length > 0 ? createRowsFromVPManagementData(data) : [];

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "0 0 0px rgba(0, 0, 0, 0.1)",
          borderRadius: "15px",
        }}
      >
        {isDataLoading ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "400px",
              }}
            >
              <CircularProgress sx={{ color: "rgb(0, 176, 80)" }} />
            </Box>
          </>
        ) : !isDataLoading && data.length == 0 ? (
          <>
            <DataNotFound />
          </>
        ) : (
          <Table aria-label="customized table">
            <TableHead>
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    padding: "10px",
                  },
                }}
              >
                <StyledTableCell align="left">S.No.</StyledTableCell>
                <StyledTableCell align="left">VP ID</StyledTableCell>
                <StyledTableCell align="left">Name</StyledTableCell>
                <StyledTableCell align="left">Mobile No.</StyledTableCell>
                <StyledTableCell align="left">Email</StyledTableCell>
                <StyledTableCell align="left">#Manager</StyledTableCell>
                <StyledTableCell align="left">#Executive</StyledTableCell>
                <StyledTableCell align="left">
                  Created Date & Time
                </StyledTableCell>
                <StyledTableCell align="left">Status</StyledTableCell>
                <StyledTableCell align="left">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& .MuiTableCell-root": {
                  padding: "11px",
                },
              }}
            >
              {rows.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="left">{row.index}</StyledTableCell>
                  <StyledTableCell align="left">{row.vpId}</StyledTableCell>
                  <StyledTableCell align="left">{row.fullName}</StyledTableCell>
                  <StyledTableCell align="left">
                    {row.mobileNumber}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {renderCellWithTooltip(row.email, 20)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.NoOfAssignedManager}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.NoOfAssignedExecutive}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.createdAt}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    style={{ color: row.status === "ACTIVE" ? "green" : "red" }}
                  >
                    {row.status}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Grid
                      style={{
                        display: "flex",
                        gap: "0.7rem",
                        alignItems: "center",
                      }}
                    >
                      <IoEyeOutline
                        onClick={() => handleViewClick(row._id)}
                        style={{ fontSize: "24px" }}
                      />
                      <BiEditAlt
                        onClick={() => handleEditClick(row._id)}
                        style={{ fontSize: "24px" }}
                      />
                      <BiBlock
                        onClick={() => {
                          handleLogoutClick(row._id, row.status);
                        }}
                        style={{
                          color: row.status === "ACTIVE" ? "green" : "red",
                          fontSize: "24px",
                        }}
                      />
                    </Grid>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      {isLogoutPopupOpen && (
        <LogoutPopup
          isOpen={isLogoutPopupOpen}
          onClose={handleLogoutConfirm}
          name={selectedUserStatus === "ACTIVE" ? "Block VP?" : "Unblock Vp?"}
          description={
            selectedUserStatus === "ACTIVE"
              ? "Are you sure you want to block this vp?"
              : "Are you sure you want to unblock this vp?"
          }
          cancelbtn="No"
          okbtn={selectedUserStatus === "ACTIVE" ? "Block" : "Unblock"}
          route="vp"
          userId={selectedUserId}
          data={data}
          setData={setData}
          type="user"
        />
      )}

      {totalPages > 1 && (
        <Grid container justifyContent="center" mt={3}>
          <Pagination
            color="success"
            count={totalPages}
            page={filter.page}
            onChange={handleChangePage}
          />
        </Grid>
      )}

      <button
        id="vp-tablesdata-download"
        style={{ display: "none" }}
        onClick={handleDownloadCSV}
      >
        Download CSV
      </button>
    </div>
  );
}
