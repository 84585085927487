import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Grid,
  Paper,
  Divider,
  Button,
  Link,
  Menu,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Box from "@mui/material/Box";
import MainLayout from "../../layout/MainLayout";
import CustomizedTable from "../../component/Table/RequestedLeadMangemetData";
import CustomizedTables from "../../component/Table/LeadsManagementData";
import LeadFilter from "../../component/Filters/LeadFilter";
import ButtonComponent from "../../component/Button";
import { getAPIHandler, postAPIHandlerForFile } from "../../config/service";
import toast from "react-hot-toast";
import MultipleReassign from "../../component/Popup/MultipleReassign";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box sx={{ padding: "0px 10px" }} {...other}>
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function LeadManagement() {
  const [value, setValue] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [totalRequests, setTotalRequests] = useState(0);
  const [totalGeneratedLeads, setTotalGeneratedLeads] = useState(0);
  const [totalBookedVisits, setTotalBookedVisits] = useState(0);
  const [leads, setLeads] = useState([]);
  const [leadStatus, setLeadStatus] = useState("");
  const [callStatus, setCallStatus] = useState("");
  const [location, setLocation] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const [checkBoxTab2, setCheckBoxTab2] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedIdsTab2, setSelectedIdsTab2] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState([
    "PropertyName",
    "ClientName",
    "ClientNumber",
    "ClientEmail",
    "RequestedDateAndTime",
  ]);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const response = await getAPIHandler({
          endPoint: "getAllLeads",
        });
        if (
          response.data &&
          response.data.result &&
          response.data.result.allLeads
        ) {
          const leadsData = response.data.result.allLeads;
          setLeads(leadsData);
          calculateLeadCounts(leadsData);
        } else {
          console.error("Unexpected response structure:", response.data);
        }
      } catch (error) {
        console.error("Error fetching leads:", error);
      }
    };

    fetchLeads();
  }, []);

  const calculateLeadCounts = (leadsData) => {
    const requests = leadsData.length;
    const generatedLeads = leadsData.filter(
      (lead) => lead.leadStatus === "GENERATEDLEAD"
    ).length;
    const bookedVisits = leadsData.filter(
      (lead) => lead.leadStatus === "BOOKEDVISIT"
    ).length;

    setTotalRequests(requests);
    setTotalGeneratedLeads(generatedLeads);
    setTotalBookedVisits(bookedVisits);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const handleLeadStatusSelect = (leadStatus) => {
    setLeadStatus(leadStatus);
  };

  const handleCallStatusSelect = (callStatus) => {
    setCallStatus(callStatus);
  };

  // const handleLoctionSelect = (location) => {
  //   setLocation(location);
  // }

  const handleLoctionSelect = (event) => {
    setLocation(event.target.value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const triggerFileInput = () => {
    document.getElementById("bulk-upload").click();
  };

  const handleToggle = () => {
    setCheckBox((prev) => !prev);
  };

  const handleToggleTab2 = () => {
    setCheckBoxTab2((prev) => !prev);
  };

  const handleCheckboxChange = (id, isChecked, tab) => {
    if (tab === 0) {
      setSelectedIds((prev) =>
        isChecked ? [...prev, id] : prev.filter((item) => item !== id)
      );
    } else {
      setSelectedIdsTab2((prev) =>
        isChecked ? [...prev, id] : prev.filter((item) => item !== id)
      );
    }
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      toast.error("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    setIsLoading(true);

    try {
      const response = await postAPIHandlerForFile({
        endPoint: "markLeadRequestUsingFile",
        dataToSend: formData,
      });

      if (response && response.status === 200) {
        toast.success("Lead requests added.");
      } else {
        toast.error(response?.data?.message || "File upload failed.");
      }
    } catch (error) {
      console.error("Error during file upload:", error);
      toast.error("An error occurred while uploading the file.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckboxReset = () => {
    setCheckBox(false);
    setCheckBoxTab2(false);
  };

  const handleColumnToggle = (column) => {
    setVisibleColumns((prev) =>
      prev.includes(column)
        ? prev.filter((col) => col !== column)
        : [...prev, column]
    );
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <MainLayout>
      <Grid
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: "20px",
        }}
      >
        <Grid item>
          <Typography sx={{ fontWeight: 700, fontSize: "30px" }}>
            Lead Management
          </Typography>
        </Grid>

        {value === 0 && (
          <Grid>
            <input
              type="file"
              accept=".xlsx"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="bulk-upload"
            />
            <Link
              href="/Sample_Leads.xlsx"
              download="SampleSheet.xlsx"
              underline="none"
              sx={{ color: "primary.main", fontSize: "14px" }}
            >
              DOWNLOAD SAMPLE SHEET
            </Link>

            <ButtonComponent
              name="Upload in bulk"
              borderRadius="100px"
              onClick={triggerFileInput}
              sx={{
                padding: "8px 16px",
                backgroundColor: "primary.main",
                color: "white",
              }}
            />

            <ButtonComponent
              variant="contained"
              borderRadius="100px"
              onClick={handleToggle}
              sx={{
                padding: "8px 16px",
                backgroundColor: "secondary.main",
                color: "white",
              }}
            >
              {checkBox ? "Unselect All" : "Select in Bulk"}
            </ButtonComponent>

            <MultipleReassign
              disabled={!checkBox}
              requestedLeadIds={selectedIds}
              requestedLeads={true}
              onApiSuccess={handleCheckboxReset}
            />
          </Grid>
        )}

        {value === 1 && (
          <Grid>
            <Button
              onClick={handleMenuOpen}
              sx={{
                color: "primary.main",
                fontSize: "14px",
              }}
            >
              Column Preference
            </Button>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {[
                "PropertyName",
                "ClientName",
                "ClientNumber",
                "ClientEmail",
                "LeadStatus",
                "CallDispositionStatus",
                "RequestedDateAndTime",
                "Location",
              ].map((column) => (
                <MenuItem key={column}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={visibleColumns.includes(column)}
                        onChange={() => handleColumnToggle(column)}
                      />
                    }
                    label={column.replace(/([A-Z])/g, " $1")}
                  />
                </MenuItem>
              ))}
            </Menu>

            <ButtonComponent
              variant="contained"
              borderRadius="100px"
              onClick={handleToggleTab2}
              sx={{
                padding: "8px 16px",
                backgroundColor: "secondary.main",
                color: "white",
              }}
            >
              {checkBoxTab2 ? "Unselect All" : "Select in Bulk"}
            </ButtonComponent>

            <MultipleReassign
              requestedLeadIds={selectedIdsTab2}
              disabled={!checkBoxTab2}
              onApiSuccess={handleCheckboxReset}
            />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2} padding={1}>
        <Grid item xs={2}></Grid>

        <Grid item xs={8}>
          <Box display="flex" justifyContent="center">
            <Tabs value={value} onChange={handleChange} centered>
              {["Requested Lead", "Generated Lead"].map((label, index) => (
                <Tab
                  key={index}
                  label={
                    <Typography
                      sx={{
                        textTransform: "none",
                        color: value === index ? "rgba(0, 176, 80, 1)" : "#333",
                      }}
                    >
                      {label}
                    </Typography>
                  }
                />
              ))}
            </Tabs>
          </Box>
        </Grid>
      </Grid>

      <CustomTabPanel value={value} index={0}>
        <Grid style={{ padding: "15px" }}>
          <LeadFilter
            searchPlaceholder={"Search by property name"}
            onSearch={handleSearch}
            onFromDateChange={handleFromDateChange}
            onToDateChange={handleToDateChange}
            onLeadStatusChange={handleLeadStatusSelect}
            onCallStatusChange={handleCallStatusSelect}
            onStateChange={handleLoctionSelect}
            onCityChange={handleLoctionSelect}
          />
        </Grid>

        <Grid style={{ padding: "15px" }}>
          {" "}
          <CustomizedTable
            searchQuery={searchQuery}
            fromDate={fromDate}
            toDate={toDate}
            checkBox={checkBox}
            selectedIds={selectedIds}
            onCheckboxChange={(id, isChecked) =>
              handleCheckboxChange(id, isChecked, 0)
            }
          />
        </Grid>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <Grid style={{ padding: "15px" }}>
          <LeadFilter
            searchPlaceholder={"Search by property name"}
            onSearch={handleSearch}
            onFromDateChange={handleFromDateChange}
            onToDateChange={handleToDateChange}
            onLeadStatusChange={handleLeadStatusSelect}
            onCallStatusChange={handleCallStatusSelect}
            onStateChange={handleLoctionSelect}
            onCityChange={handleLoctionSelect}
          />
        </Grid>
        <Grid style={{ padding: "15px" }}>
          <CustomizedTables
            visibleColumns={visibleColumns}
            searchQuery={searchQuery}
            fromDate={fromDate}
            toDate={toDate}
            checkBox={checkBoxTab2}
            selectedIds={selectedIdsTab2}
            leadStatus={leadStatus}
            callDispositionStatus={callStatus}
            location={location}
            onCheckboxChange={(id, isChecked) =>
              handleCheckboxChange(id, isChecked, 1)
            }
          />
        </Grid>
      </CustomTabPanel>
    </MainLayout>
  );
}
