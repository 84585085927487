import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FieldForInput from "../../component/input_field/FieldForInput.js";
import Button from "../../component/button/Button";
import BubbleLoader from "../../component/Loader/BubbleLoader.js";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import { loginSchema } from "../../schemas/index.js";
import { postAPIHandlerWithoutToken } from "../../config/service.js";
import { AuthContext } from "../../context/AuthContext.js";
import AuthLayout from "../../layout/AuthLayout.js";
import { Box, styled, Checkbox, Typography } from "@mui/material";

const DisplayStart = styled(Box)({
  display: "flex",
  alignItems: "center",

});

const CheckboxAll = styled(Checkbox)({
  "&.MuiCheckbox-root": {
    padding: "9px 6px 9px 0px",
    color: "#2e6cd7",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "28px", 
  },
});

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem("password") != null
  );
  const initialValues = {
    identifier: localStorage.getItem("email") || "",
    password: localStorage.getItem("password") || "",
  };

  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const { values, errors, handleBlur, handleChange, touched, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: loginSchema,
      onSubmit: async (values) => {
        setLoading(true)
        try {
          const response = await postAPIHandlerWithoutToken({
            endPoint: "userLogin",
            dataToSend: values,
          });
          if (response && response.data.responseCode === 200) {
            const userData = response.data.result;
            
            if (rememberMe) {
              localStorage.setItem("email", values.identifier);
              localStorage.setItem("password", values.password);
            } else {
              localStorage.removeItem("email");
              localStorage.removeItem("password");
            }
            login(userData);
            const { userType } = userData;
            if (userType === "ADMIN" || userType === "VP") {
              toast.success("Login Successful");
              navigate("/dashboard", { replace: true });
            } else if (userType === "EXECUTIVE" || userType === "MANAGER") {
              toast.error("Not authorized for admin panel.");
            }
          } else {
            toast.error("Please provide valid login credentials.");
          }
        } catch (error) {
          toast.error("Please provide valid login credentials.");
          console.error("Error:", error);
        }
        setLoading(false)
      },
    });

  return (
    <AuthLayout>
      <form className="admin-login-content" onSubmit={handleSubmit}>
        <div className="admin-login-div">
          <h2 className="admin-login-text">Administrator Login</h2>
        </div>
        <div>
          <label className="login-input-label">Email/Mobile Number</label>
          <FieldForInput
            className="login-field"
            name="identifier"
            placeholder="Enter email/mobile number"
            label="Email/Mobile number"
            width="572px"
            height="60px"
            borderRadius="100px"
            marginTop="10px"
            marginBottom="20px"
            border=" 1px solid rgba(225, 225, 225, 1)"
            onBlur={handleBlur}
            onChange={handleChange}
            maxLength={100}
            value={values.identifier}
          />
          {errors.identifier && touched.identifier ? (
            <p style={{ color: "red", marginTop: "-40px", marginLeft: "20px" }}>
              {errors.identifier}
            </p>
          ) : null}
        </div>
        <div>
          <label className="login-input-label">Password</label>
          <FieldForInput
            className="login-pass-field"
            name="password"
            type="password"
            placeholder="Enter password"
            label="Password"
            width="572px"
            height="60px"
            borderRadius="100px"
            border=" 1px solid rgba(225, 225, 225, 1)"
            onBlur={handleBlur}
            onChange={handleChange}
            maxLength={20}
            value={values.password}
            style={{ marginBottom: "10px" }}
          />
          {errors.password && touched.password ? (
            <p style={{ color: "red", marginTop: "-40px", marginLeft: "20px" }}>
              {errors.password}
            </p>
          ) : null}
        </div>

        
        <DisplayStart  sx={{ marginTop: "0px" }}>
          <CheckboxAll
            checked={rememberMe}
            onChange={() => setRememberMe(!rememberMe)}
          />
          <Typography variant="body2" sx={{ color: "#000", fontWeight: "500", fontFamily: "lato" }}>
            Remember Me
          </Typography>
          
        </DisplayStart>
        

        <div className="login-button-div">
        <Button
        backgroundColor="rgba(0, 176, 80, 1)"
        color="white"
        width="572px"
        height="60px"
        borderRadius="100px"
        border="none"
        type="submit"
        disabled={loading}
      >
        {loading ? <BubbleLoader color="#FFF" size="1rem" /> : "LOGIN"}
      </Button>

        </div>

        <div className="forgot-password-div">
          <Link
            to={"/forgotPassword"}
            style={{ textAlign: "center", textDecoration: "none" }}
            className="forgot-password-link"
          >
            <p className="forgot-password-text">Forgot Password?</p>
          </Link>
        </div>
        
      </form>
    </AuthLayout>
  );
};

export default Login;
