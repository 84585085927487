import vector1 from "../assets/vector1.png";
import vector2 from "../assets/vector2.png";
import vector3 from "../assets/vector3.png";
import vector4 from "../assets/vector4.png";
import vector5 from "../assets/vector5.png";
import vector6 from "../assets/vector6.png";
import vector7 from "../assets/vector7.png";
import vector8 from "../assets/vector8.png";
import vector9 from "../assets/vector9.png";
import vector10 from "../assets/vector10.png";
import vector11 from "../assets/vector11.png";
import View from "../assets/View.png";
import Edit from "../assets/Edit.png";
import Delete from "../assets/Delete.png";
import Dashboard from "../assets/Dashboard.svg";
import Developer from "../assets/Developer.svg";
import Lead from "../assets/Lead.svg";
import Manager from "../assets/Manager.svg"
import vp from "../assets/VP.svg";
import Property from "../assets/Property.svg";
import Executive from "../assets/Executive.svg";
import Broadcast from "../assets/Broadcast.svg";
import Account from "../assets/Account.svg";



export const SidebarData = [
  {
    icon1: Dashboard,
    icon2: vector1,
    name: "Dashboard",
  },
  {
    icon1: Developer,
    icon2: vector2,
    name: "Developer Management",
  },
  {
    icon1: Property,
    icon2: vector4,
    name: "Property Management",
  },
  {
    icon1: Executive,
    icon2: vector3,
    name: "Executive Management",
  },
  {
    icon1: Manager,
    icon2: vector5,
    name: "Manager Management",
  },
  {
    icon1: vp,
    icon2: vector6,
    name: "VP Management",
  },
  {
    icon1: Lead,
    icon2: vector7,
    name: "Leads Management",
  },
  {
    icon1: vector8,
    icon2: vector8,
    name: "Report Management",
  },
  {
    icon1: Broadcast,
    icon2: vector9,
    name: "Broadcast Management",
  },
  {
    icon1: Broadcast,
    icon2: vector9,
    name: "Content Management",
  },
  {
    icon1: Broadcast,
    icon2: vector9,
    name: "Token Management",
  },
  {
    icon1: Broadcast,
    icon2: vector9,
    name: "Url Management",
  },
  {
    icon1: Account,
    icon2: vector10,
    name: "My Account",
  },
  {
    icon1: vector11,
    icon2: vector11,
    name: "Logout",
  },
];

export const VPManagementData = [
  {
    SNo: 1,
    VPId: "#245",
    VPName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    NoOfAssignedManager: 5,
    NoOfAssignedExecutive: 6,
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },

  {
    SNo: 2,
    VPId: "#245",
    VPName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    NoOfAssignedManager: 5,
    NoOfAssignedExecutive: 6,
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 3,
    VPId: "#245",
    VPName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    NoOfAssignedManager: 5,
    NoOfAssignedExecutive: 6,
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 4,
    VPId: "#245",
    VPName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    NoOfAssignedManager: 5,
    NoOfAssignedExecutive: 6,
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 5,
    VPId: "#245",
    VPName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    NoOfAssignedManager: 5,
    NoOfAssignedExecutive: 6,
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 6,
    VPId: "#245",
    VPName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    NoOfAssignedManager: 5,
    NoOfAssignedExecutive: 6,
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 7,
    VPId: "#245",
    VPName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    NoOfAssignedManager: 5,
    NoOfAssignedExecutive: 6,
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 7,
    VPId: "#245",
    VPName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    NoOfAssignedManager: 5,
    NoOfAssignedExecutive: 6,
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 7,
    VPId: "#245",
    VPName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    NoOfAssignedManager: 5,
    NoOfAssignedExecutive: 6,
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 7,
    VPId: "#245",
    VPName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    NoOfAssignedManager: 5,
    NoOfAssignedExecutive: 6,
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 7,
    VPId: "#245",
    VPName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    NoOfAssignedManager: 5,
    NoOfAssignedExecutive: 6,
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 7,
    VPId: "#245",
    VPName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    NoOfAssignedManager: 5,
    NoOfAssignedExecutive: 6,
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 7,
    VPId: "#245",
    VPName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    NoOfAssignedManager: 5,
    NoOfAssignedExecutive: 6,
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
];

export const PropertyManagementData = [
  {
    SNo: 1,
    PropertyName: "Experions the Westernlies",
    ListedDate: "20-10-2022",
    Type: "Residential",
    PossessionStatus: "Today",
    ConstructionStatus: "Under Construction",
    Status: "Active",
    AddedBy: "Admin",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 2,
    PropertyName: "Experions the Westernlies",
    ListedDate: "20-10-2022",
    Type: "Residential",
    PossessionStatus: "Today",
    ConstructionStatus: "Under Construction",
    Status: "Active",
    AddedBy: "Admin",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 3,
    PropertyName: "Experions the Westernlies",
    ListedDate: "20-10-2022",
    Type: "Residential",
    PossessionStatus: "Today",
    ConstructionStatus: "Under Construction",
    Status: "Active",
    AddedBy: "Admin",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 4,
    PropertyName: "Experions the Westernlies",
    ListedDate: "20-10-2022",
    Type: "Residential",
    PossessionStatus: "Today",
    ConstructionStatus: "Under Construction",
    Status: "Active",
    AddedBy: "Admin",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 5,
    PropertyName: "Experions the Westernlies",
    ListedDate: "20-10-2022",
    Type: "Residential",
    PossessionStatus: "Today",
    ConstructionStatus: "Under Construction",
    Status: "Active",
    AddedBy: "Admin",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 6,
    PropertyName: "Experions the Westernlies",
    ListedDate: "20-10-2022",
    Type: "Residential",
    PossessionStatus: "Today",
    ConstructionStatus: "Under Construction",
    Status: "Active",
    AddedBy: "Admin",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 7,
    PropertyName: "Experions the Westernlies",
    ListedDate: "20-10-2022",
    Type: "Residential",
    PossessionStatus: "Today",
    ConstructionStatus: "Under Construction",
    Status: "Active",
    AddedBy: "Admin",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 8,
    PropertyName: "Experions the Westernlies",
    ListedDate: "20-10-2022",
    Type: "Residential",
    PossessionStatus: "Today",
    ConstructionStatus: "Under Construction",
    Status: "Active",
    AddedBy: "Admin",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 9,
    PropertyName: "Experions the Westernlies",
    ListedDate: "20-10-2022",
    Type: "Residential",
    PossessionStatus: "Today",
    ConstructionStatus: "Under Construction",
    Status: "Active",
    AddedBy: "Admin",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 10,
    PropertyName: "Experions the Westernlies",
    ListedDate: "20-10-2022",
    Type: "Residential",
    PossessionStatus: "Today",
    ConstructionStatus: "Under Construction",
    Status: "Active",
    AddedBy: "Admin",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
];

export const ExecutiveManagementData = [
  {
    SNo: 1,
    ExecutiveId: "#224",
    ExecutiveName: "Arvind",
    ExecutiveType: "Sales",
    ManagerName: "Mukesh",
    VPName: "Ritesh",
    MobileNumber: "8968555645",
    EmailAddress: "arvind34@gmail.com",
    CreatedDateAndTime: "16 Aug, 2022",
    AddedBy: "Admin",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 1,
    ExecutiveId: "#224",
    ExecutiveName: "Arvind",
    ExecutiveType: "Sales",
    ManagerName: "Mukesh",
    VPName: "Ritesh",
    MobileNumber: "8968555645",
    EmailAddress: "arvind34@gmail.com",
    CreatedDateAndTime: "16 Aug, 2022",
    AddedBy: "Admin",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 1,
    ExecutiveId: "#224",
    ExecutiveName: "Arvind",
    ExecutiveType: "Sales",
    ManagerName: "Mukesh",
    VPName: "Ritesh",
    MobileNumber: "8968555645",
    EmailAddress: "arvind34@gmail.com",
    CreatedDateAndTime: "16 Aug, 2022",
    AddedBy: "Admin",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 1,
    ExecutiveId: "#224",
    ExecutiveName: "Arvind",
    ExecutiveType: "Sales",
    ManagerName: "Mukesh",
    VPName: "Ritesh",
    MobileNumber: "+8968555645",
    EmailAddress: "arvind34@gmail.com",
    CreatedDateAndTime: "16 Aug, 2022",
    AddedBy: "Admin",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },

  {
    SNo: 1,
    ExecutiveId: "#224",
    ExecutiveName: "Arvind",
    ExecutiveType: "Sales",
    ManagerName: "Mukesh",
    VPName: "Ritesh",
    MobileNumber: "8968555645",
    EmailAddress: "arvind34@gmail.com",
    CreatedDateAndTime: "16 Aug, 2022",
    AddedBy: "Admin",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 1,
    ExecutiveId: "#224",
    ExecutiveName: "Arvind",
    ExecutiveType: "Sales",
    ManagerName: "Mukesh",
    VPName: "Ritesh",
    MobileNumber: "8968555645",
    EmailAddress: "arvind34@gmail.com",
    CreatedDateAndTime: "16 Aug, 2022",
    AddedBy: "Admin",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },

  {
    SNo: 1,
    ExecutiveId: "#224",
    ExecutiveName: "Arvind",
    ExecutiveType: "Sales",
    ManagerName: "Mukesh",
    VPName: "Ritesh",
    MobileNumber: "8968555645",
    EmailAddress: "arvind34@gmail.com",
    CreatedDateAndTime: "16 Aug, 2022",
    AddedBy: "Admin",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 1,
    ExecutiveId: "#224",
    ExecutiveName: "Arvind",
    ExecutiveType: "Sales",
    ManagerName: "Mukesh",
    VPName: "Ritesh",
    MobileNumber: "8968555645",
    EmailAddress: "arvind34@gmail.com",
    CreatedDateAndTime: "16 Aug, 2022",
    AddedBy: "Admin",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },

  {
    SNo: 1,
    ExecutiveId: "#224",
    ExecutiveName: "Arvind",
    ExecutiveType: "Sales",
    ManagerName: "Mukesh",
    VPName: "Ritesh",
    MobileNumber: "8968555645",
    EmailAddress: "arvind34@gmail.com",
    CreatedDateAndTime: "16 Aug, 2022",
    AddedBy: "Admin",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 1,
    ExecutiveId: "#224",
    ExecutiveName: "Arvind",
    ExecutiveType: "Sales",
    ManagerName: "Mukesh",
    VPName: "Ritesh",
    MobileNumber: "8968555645",
    EmailAddress: "arvind34@gmail.com",
    CreatedDateAndTime: "16 Aug, 2022",
    AddedBy: "Admin",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },

  {
    SNo: 1,
    ExecutiveId: "#224",
    ExecutiveName: "Arvind",
    ExecutiveType: "Sales",
    ManagerName: "Mukesh",
    VPName: "Ritesh",
    MobileNumber: "8968555645",
    EmailAddress: "arvind34@gmail.com",
    CreatedDateAndTime: "16 Aug, 2022",
    AddedBy: "Admin",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 1,
    ExecutiveId: "#224",
    ExecutiveName: "Arvind",
    ExecutiveType: "Sales",
    ManagerName: "Mukesh",
    VPName: "Ritesh",
    MobileNumber: "8968555645",
    EmailAddress: "arvind34@gmail.com",
    CreatedDateAndTime: "16 Aug, 2022",
    AddedBy: "Admin",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },

  {
    SNo: 1,
    ExecutiveId: "#224",
    ExecutiveName: "Arvind",
    ExecutiveType: "Sales",
    ManagerName: "Mukesh",
    VPName: "Ritesh",
    MobileNumber: "8968555645",
    EmailAddress: "arvind34@gmail.com",
    CreatedDateAndTime: "16 Aug, 2022",
    AddedBy: "Admin",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 1,
    ExecutiveId: "#224",
    ExecutiveName: "Arvind",
    ExecutiveType: "Sales",
    ManagerName: "Mukesh",
    VPName: "Ritesh",
    MobileNumber: "8968555645",
    EmailAddress: "arvind34@gmail.com",
    CreatedDateAndTime: "16 Aug, 2022",
    AddedBy: "Admin",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
];

export const ManagerManagementData = [
  {
    SNo: 1,
    ManagerId: "#245",
    ManagerName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    NoOfAssignedExecutive: 6,
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    AddedBy: "Admin",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 1,
    ManagerId: "#245",
    ManagerName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    NoOfAssignedExecutive: 6,
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    AddedBy: "Admin",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 1,
    ManagerId: "#245",
    ManagerName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    NoOfAssignedExecutive: 6,
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    AddedBy: "Admin",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 1,
    ManagerId: "#245",
    ManagerName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    NoOfAssignedExecutive: 6,
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    AddedBy: "Admin",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 1,
    ManagerId: "#245",
    ManagerName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    NoOfAssignedExecutive: 6,
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    AddedBy: "Admin",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 1,
    ManagerId: "#245",
    ManagerName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    NoOfAssignedExecutive: 6,
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    AddedBy: "Admin",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 1,
    ManagerId: "#245",
    ManagerName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    NoOfAssignedExecutive: 6,
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    AddedBy: "Admin",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 1,
    ManagerId: "#245",
    ManagerName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    NoOfAssignedExecutive: 6,
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    AddedBy: "Admin",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
];

export const DeveloperManagementData = [
  {
    SNo: 1,
    DeveloperName: "Arvind Tyagi",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 2,
    DeveloperName: "Arvind Tyagi",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 3,
    DeveloperName: "Arvind Tyagi",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 4,
    DeveloperName: "Arvind Tyagi",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 5,
    DeveloperName: "Arvind Tyagi",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 6,
    DeveloperName: "Arvind Tyagi",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 7,
    DeveloperName: "Arvind Tyagi",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 8,
    DeveloperName: "Arvind Tyagi",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 9,
    DeveloperName: "Arvind Tyagi",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 10,
    DeveloperName: "Arvind Tyagi",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 11,
    DeveloperName: "Arvind Tyagi",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 12,
    DeveloperName: "Arvind Tyagi",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 13,
    DeveloperName: "Arvind Tyagi",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 14,
    DeveloperName: "Arvind Tyagi",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 15,
    DeveloperName: "Arvind Tyagi",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 16,
    DeveloperName: "Arvind Tyagi",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Active",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
];

export const detailData = [
  {
    name: "Role Type",
    about: "Sales",
  },
  {
    name: "First Name",
    about: "Arvind",
  },
  {
    name: "Last Name",
    about: "Tyagi",
  },
  {
    name: "Created Date & Time",
    about: "16. Aug.2022, 06:30 PM",
  },
  {
    name: "Mobile Number",
    about: "+91-9585858585",
  },
  {
    name: "Status",
    about: "Active",
  },
  {
    name: "State",
    about: "New Delhi",
  },
  {
    name: "Email Address",
    about: "abc@gmail.com",
  },
  {
    name: "City",
    about: "Delhi",
  },
];

export const LeadDetailsHistory = [
  {
    BookedVisit: "26th Dec. 2023",
    PropertyName: "Westerlies",
    TeamMember: "Ajay Sharma",
    DeveloperName: "experion",
  },
  {
    BookedVisit: "26th Dec. 2023",
    PropertyName: "Westerlies",
    TeamMember: "Ajay Sharma",
    DeveloperName: "experion",
  },
  {
    BookedVisit: "26th Dec. 2023",
    PropertyName: "Westerlies",
    TeamMember: "Ajay Sharma",
    DeveloperName: "experion",
  },
  {
    BookedVisit: "26th Dec. 2023",
    PropertyName: "Westerlies",
    TeamMember: "Ajay Sharma",
    DeveloperName: "experion",
  },
  {
    BookedVisit: "26th Dec. 2023",
    PropertyName: "Westerlies",
    TeamMember: "Ajay Sharma",
    DeveloperName: "experion",
  },
  {
    BookedVisit: "26th Dec. 2023",
    PropertyName: "Westerlies",
    TeamMember: "Ajay Sharma",
    DeveloperName: "experion",
  },
  {
    BookedVisit: "26th Dec. 2023",
    PropertyName: "Westerlies",
    TeamMember: "Ajay Sharma",
    DeveloperName: "experion",
  },
  {
    BookedVisit: "26th Dec. 2023",
    PropertyName: "Westerlies",
    TeamMember: "Ajay Sharma",
    DeveloperName: "experion",
  },
  {
    BookedVisit: "26th Dec. 2023",
    PropertyName: "Westerlies",
    TeamMember: "Ajay Sharma",
    DeveloperName: "experion",
  },
  {
    BookedVisit: "26th Dec. 2023",
    PropertyName: "Westerlies",
    TeamMember: "Ajay Sharma",
    DeveloperName: "experion",
  },
  {
    BookedVisit: "26th Dec. 2023",
    PropertyName: "Westerlies",
    TeamMember: "Ajay Sharma",
    DeveloperName: "experion",
  },
  {
    BookedVisit: "26th Dec. 2023",
    PropertyName: "Westerlies",
    TeamMember: "Ajay Sharma",
    DeveloperName: "experion",
  },
];

export const LeadsManagementData = [
  {
    PropertyName: "Experion The Westernlies",
    ClientName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    Status: "Resent Request",
    RequestedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    View: View,
    Edit: Edit,
  },
  {
    PropertyName: "Experion The Westernlies",
    ClientName: "Raghav",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    Status: "Generated Lead",
    RequestedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    View: View,
    Edit: Edit,
  },
  {
    PropertyName: "Experion The Westernlies",
    ClientName: "Vishal",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    Status: "Booked Visit",
    RequestedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    View: View,
    Edit: Edit,
  },
  {
    PropertyName: "Experion The Westernlies",
    ClientName: "Amit",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    Status: "Resent Request",
    RequestedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    View: View,
    Edit: Edit,
  },
  {
    PropertyName: "Experion The Westernlies",
    ClientName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    Status: "Resent Request",
    RequestedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    View: View,
    Edit: Edit,
  },
  {
    PropertyName: "Experion The Westernlies",
    ClientName: "Raghav",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    Status: "Generated Lead",
    RequestedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    View: View,
    Edit: Edit,
  },
  {
    PropertyName: "Experion The Westernlies",
    ClientName: "Vishal",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    Status: "Booked Visit",
    RequestedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    View: View,
    Edit: Edit,
  },
  {
    PropertyName: "Experion The Westernlies",
    ClientName: "Amit",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    Status: "Resent Request",
    RequestedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    View: View,
    Edit: Edit,
  },
  {
    PropertyName: "Experion The Westernlies",
    ClientName: "Arvind",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    Status: "Resent Request",
    RequestedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    View: View,
    Edit: Edit,
  },
  {
    PropertyName: "Experion The Westernlies",
    ClientName: "Raghav",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    Status: "Generated Lead",
    RequestedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    View: View,
    Edit: Edit,
  },
  {
    PropertyName: "Experion The Westernlies",
    ClientName: "Vishal",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    Status: "Booked Visit",
    RequestedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    View: View,
    Edit: Edit,
  },
  {
    PropertyName: "Experion The Westernlies",
    ClientName: "Amit",
    MobileNumber: "+91-8968555645",
    EmailAddress: "arvind34@gmail.com",
    Status: "Resent Request",
    RequestedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    View: View,
    Edit: Edit,
  },
];

export const ReportManagementData = [
  {
    ProjectName: "Experion The WesternLies",
    Source: "99 acres",
    VPName: "Amit",
    ManagerName: "Ankur",
    ExecutiveName: "Rahul",
    GeneratedLeads: 5,
    BookedVisit: 5,
    ConfirmedBookings: 6,
    Date: "16 Aug, 2022",
  },
  {
    ProjectName: "Experion The WesternLies",
    Source: "99 acres",
    VPName: "Raghav",
    ManagerName: "Ankur",
    ExecutiveName: "Rahul",
    GeneratedLeads: 5,
    BookedVisit: 5,
    ConfirmedBookings: 6,
    Date: "16 Aug, 2022",
  },
  {
    ProjectName: "Experion The WesternLies",
    Source: "99 acres",
    VPName: "Vishal",
    ManagerName: "Ankur",
    ExecutiveName: "Rahul",
    GeneratedLeads: 5,
    BookedVisit: 5,
    ConfirmedBookings: 6,
    Date: "16 Aug, 2022",
  },
  {
    ProjectName: "Experion The WesternLies",
    Source: "99 acres",
    VPName: "Amit",
    ManagerName: "Ankur",
    ExecutiveName: "Rahul",
    GeneratedLeads: 5,
    BookedVisit: 5,
    ConfirmedBookings: 6,
    Date: "16 Aug, 2022",
  },
  {
    ProjectName: "Experion The WesternLies",
    Source: "99 acres",
    VPName: "Raghav",
    ManagerName: "Ankur",
    ExecutiveName: "Rahul",
    GeneratedLeads: 5,
    BookedVisit: 5,
    ConfirmedBookings: 6,
    Date: "16 Aug, 2022",
  },
  {
    ProjectName: "Experion The WesternLies",
    Source: "99 acres",
    VPName: "Vishal",
    ManagerName: "Ankur",
    ExecutiveName: "Rahul",
    GeneratedLeads: 5,
    BookedVisit: 5,
    ConfirmedBookings: 6,
    Date: "16 Aug, 2022",
  },
  {
    ProjectName: "Experion The WesternLies",
    Source: "99 acres",
    VPName: "Amit",
    ManagerName: "Ankur",
    ExecutiveName: "Rahul",
    GeneratedLeads: 5,
    BookedVisit: 5,
    ConfirmedBookings: 6,
    Date: "16 Aug, 2022",
  },
  {
    ProjectName: "Experion The WesternLies",
    Source: "99 acres",
    VPName: "Raghav",
    ManagerName: "Ankur",
    ExecutiveName: "Rahul",
    GeneratedLeads: 5,
    BookedVisit: 5,
    ConfirmedBookings: 6,
    Date: "16 Aug, 2022",
  },
  {
    ProjectName: "Experion The WesternLies",
    Source: "99 acres",
    VPName: "Vishal",
    ManagerName: "Ankur",
    ExecutiveName: "Rahul",
    GeneratedLeads: 5,
    BookedVisit: 5,
    ConfirmedBookings: 6,
    Date: "16 Aug, 2022",
  },
];

export const BroadcastManagementData = [
  {
    SNo: 1,
    Title: "WS-BrokeredNotification Version 1.3 OASIS Standard",
    UserType: "Manager",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Sended",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 2,
    Title: "WS-BrokeredNotification Version 1.3 OASIS Standard",
    UserType: "Executive",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "-",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 3,
    Title: "WS-BrokeredNotification Version 1.3 OASIS Standard",
    UserType: "Manager",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Sended",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 4,
    Title: "WS-BrokeredNotification Version 1.3 OASIS Standard",
    UserType: "Executive",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "-",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 5,
    Title: "WS-BrokeredNotification Version 1.3 OASIS Standard",
    UserType: "Manager",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Sended",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 6,
    Title: "WS-BrokeredNotification Version 1.3 OASIS Standard",
    UserType: "Executive",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "-",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 7,
    Title: "WS-BrokeredNotification Version 1.3 OASIS Standard",
    UserType: "Manager",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "Sended",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
  {
    SNo: 8,
    Title: "WS-BrokeredNotification Version 1.3 OASIS Standard",
    UserType: "Executive",
    CreatedDateAndTime: "16 Aug, 2022 12:34 A.M ",
    Status: "-",
    View: View,
    Edit: Edit,
    Delete: Delete,
  },
];

export const AdsManagementData = [
  { 
    AdsId: "ADS001",
    ProjectName: "Experion The WesternLies",
    ListedDate: "16 Aug, 2022 12:34 A.M ",
    Status: "ACTIVE"
  },
  { 
    AdsId: "ADS002",
    ProjectName: "Experion The WesternLies",
    ListedDate: "16 Aug, 2022 12:34 A.M ",
    Status: "ACTIVE"
  },
  { 
    AdsId: "ADS003",
    ProjectName: "Experion The WesternLies",
    ListedDate: "16 Aug, 2022 12:34 A.M ",
    Status: "ACTIVE"
  },
  { 
    AdsId: "ADS004",
    ProjectName: "Experion The WesternLies",
    ListedDate: "16 Aug, 2022 12:34 A.M ",
    Status: "ACTIVE"
  },
  { 
    AdsId: "ADS005",
    ProjectName: "Experion The WesternLies",
    ListedDate: "16 Aug, 2022 12:34 A.M ",
    Status: "ACTIVE"
  },
  { 
    AdsId: "ADS006",
    ProjectName: "Experion The WesternLies",
    ListedDate: "16 Aug, 2022 12:34 A.M ",
    Status: "ACTIVE"
  },
  { 
    AdsId: "ADS007",
    ProjectName: "Experion The WesternLies",
    ListedDate: "16 Aug, 2022 12:34 A.M ",
    Status: "ACTIVE"
  },
  { 
    AdsId: "ADS008",
    ProjectName: "Experion The WesternLies",
    ListedDate: "16 Aug, 2022 12:34 A.M ",
    Status: "ACTIVE"
  },
  { 
    AdsId: "ADS009",
    ProjectName: "Experion The WesternLies",
    ListedDate: "16 Aug, 2022 12:34 A.M ",
    Status: "ACTIVE"
  },
  { 
    AdsId: "ADS0010",
    ProjectName: "Experion The WesternLies",
    ListedDate: "16 Aug, 2022 12:34 A.M ",
    Status: "ACTIVE"
  },
  
  

  
  
];

export const statesAndCities = {
  "Andhra Pradesh": [
    "Visakhapatnam",
    "Vijayawada",
    "Guntur",
    "Nellore",
    "Tirupati",
    "Kurnool",
    "Rajahmundry",
    "Kadapa",
    "Anantapur",
    "Chittoor",
  ],
  "Arunachal Pradesh": ["Itanagar", "Tawang", "Ziro", "Pasighat", "Bomdila"],
  Assam: [
    "Guwahati",
    "Silchar",
    "Dibrugarh",
    "Jorhat",
    "Tinsukia",
    "Nagaon",
    "Tezpur",
    "Sivasagar",
  ],
  Bihar: [
    "Patna",
    "Gaya",
    "Bhagalpur",
    "Muzaffarpur",
    "Darbhanga",
    "Purnia",
    "Chapra",
    "Hajipur",
    "Bihar Sharif",
    "Arrah",
  ],
  Chhattisgarh: [
    "Raipur",
    "Bilaspur",
    "Durg",
    "Korba",
    "Bhilai",
    "Rajnandgaon",
    "Jagdalpur",
    "Ambikapur",
    "Raigarh",
  ],
  Goa: ["Panaji", "Margao", "Vasco da Gama", "Mapusa", "Ponda"],
  Gujarat: [
    "Ahmedabad",
    "Surat",
    "Vadodara",
    "Rajkot",
    "Bhavnagar",
    "Jamnagar",
    "Junagadh",
    "Gandhinagar",
    "Anand",
    "Navsari",
  ],
  Haryana: [
    "Gurugram",
    "Faridabad",
    "Panipat",
    "Ambala",
    "Karnal",
    "Hisar",
    "Sonipat",
    "Yamunanagar",
    "Rohtak",
    "Bhiwani",
  ],
  "Himachal Pradesh": [
    "Shimla",
    "Manali",
    "Dharamshala",
    "Solan",
    "Mandi",
    "Kullu",
    "Palampur",
    "Chamba",
    "Hamirpur",
  ],
  Jharkhand: [
    "Ranchi",
    "Jamshedpur",
    "Dhanbad",
    "Bokaro",
    "Deoghar",
    "Hazaribagh",
    "Ramgarh",
    "Giridih",
    "Phusro",
  ],
  Karnataka: [
    "Bengaluru",
    "Mysuru",
    "Mangaluru",
    "Hubballi",
    "Belagavi",
    "Davanagere",
    "Ballari",
    "Tumakuru",
    "Shivamogga",
    "Udupi",
  ],
  Kerala: [
    "Thiruvananthapuram",
    "Kochi",
    "Kozhikode",
    "Thrissur",
    "Kannur",
    "Kollam",
    "Alappuzha",
    "Palakkad",
    "Malappuram",
  ],
  "Madhya Pradesh": [
    "Bhopal",
    "Indore",
    "Gwalior",
    "Jabalpur",
    "Ujjain",
    "Sagar",
    "Satna",
    "Rewa",
    "Ratlam",
    "Shivpuri",
  ],
  Maharashtra: [
    "Mumbai",
    "Pune",
    "Nagpur",
    "Nashik",
    "Thane",
    "Aurangabad",
    "Solapur",
    "Kolhapur",
    "Amravati",
    "Sangli",
  ],
  Manipur: ["Imphal", "Churachandpur", "Thoubal", "Bishnupur", "Ukhrul"],
  Meghalaya: ["Shillong", "Tura", "Cherrapunji", "Jowai", "Nongpoh"],
  Mizoram: ["Aizawl", "Lunglei", "Champhai", "Serchhip", "Kolasib"],
  Nagaland: ["Kohima", "Dimapur", "Mokokchung", "Tuensang", "Mon"],
  Odisha: [
    "Bhubaneswar",
    "Cuttack",
    "Rourkela",
    "Sambalpur",
    "Berhampur",
    "Puri",
    "Balasore",
    "Bhadrak",
    "Baripada",
    "Jeypore",
  ],
  Punjab: [
    "Chandigarh",
    "Amritsar",
    "Ludhiana",
    "Jalandhar",
    "Patiala",
    "Bathinda",
    "Mohali",
    "Hoshiarpur",
    "Pathankot",
    "Moga",
  ],
  Rajasthan: [
    "Jaipur",
    "Udaipur",
    "Jodhpur",
    "Kota",
    "Ajmer",
    "Bikaner",
    "Alwar",
    "Bharatpur",
    "Sikar",
    "Pali",
  ],
  Sikkim: ["Gangtok", "Namchi", "Pelling", "Gyalshing", "Mangan"],
  "Tamil Nadu": [
    "Chennai",
    "Coimbatore",
    "Madurai",
    "Tiruchirappalli",
    "Salem",
    "Erode",
    "Tirunelveli",
    "Vellore",
    "Thoothukudi",
  ],
  Telangana: [
    "Hyderabad",
    "Warangal",
    "Nizamabad",
    "Khammam",
    "Karimnagar",
    "Mahbubnagar",
    "Ramagundam",
    "Adilabad",
  ],
  Tripura: ["Agartala", "Udaipur", "Dharmanagar", "Kailashahar", "Belonia"],
  Uttarakhand: [
    "Dehradun",
    "Haridwar",
    "Nainital",
    "Rishikesh",
    "Haldwani",
    "Almora",
    "Pithoragarh",
    "Rudrapur",
  ],
  "Uttar Pradesh": [
    "Lucknow",
    "Kanpur",
    "Varanasi",
    "Agra",
    "Meerut",
    "Allahabad (Prayagraj)",
    "Gorakhpur",
    "Bareilly",
    "Aligarh",
    "Moradabad",
  ],
  "West Bengal": [
    "Kolkata",
    "Darjeeling",
    "Asansol",
    "Siliguri",
    "Durgapur",
    "Howrah",
    "Kharagpur",
    "Malda",
    "Jalpaiguri",
  ],
  "Andaman and Nicobar Islands": [
    "Port Blair",
    "Havelock Island",
    "Neil Island",
    "Rangat",
    "Diglipur",
  ],
};
;

export const leadOptions = [
  { label: "Resent Request", value: "RESENTREQUEST" },
  { label: "Generated Lead", value: "GENERATEDLEAD" },
  { label: "Booked Visit", value: "BOOKEDVISIT" },
];

export const callOptions = [
  { label: "Set Reminder", value: "SETREMINDER" },
  { label: "Busy", value: "BUSY" },
  { label: "Not Interested", value: "NOTINTERESTED" },
  { label: "Not Connected", value: "NOTCONNECTED" },
  { label: "Wrong Number", value: "WRONGNUMBER" },
  { label: "Booked Visit", value: "BOOKEDVISIT" },
  { label: "Mark as Lead", value: "MARKASLEAD" },
];

