import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, Pagination, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import { getAPIHandler } from "../../config/service";
import DataNotFound from "../DataNotFound";
import { format } from "date-fns";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(0, 176, 80)",
    color: theme.palette.common.white,
    fontSize: "1rem",
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(even)": {
    backgroundColor: "rgba(0, 176, 80, 0.05)",
  },
  "&:last-child td, &:last-child th": {},
  "& td ": {},
}));

export default function CustomizedTables(props) {
  const { searchQuery, fromDate, toDate, managerId } = props;

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({ page: 1, limit: 10 });
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, [filter, searchQuery, fromDate, toDate, managerId]);

  const handleChangePage = (event, value) => {
    setFilter({ ...filter, page: value });
  };

  const handleViewClick = (executiveId) => {
    navigate(`/executivemanagement/executivedetail/${executiveId}`);
  };

  const getData = async () => {
    try {
      const formattedFromDate = fromDate
        ? format(new Date(fromDate), "yyyy-MM-dd")
        : undefined;
      const formattedToDate = toDate
        ? format(new Date(toDate), "yyyy-MM-dd")
        : undefined;

      const paramsData = {
        ...filter,
        name: searchQuery || undefined,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        managerId: managerId || undefined,
      };

      const response = await getAPIHandler({
        endPoint: "listExecutivesByManagerOrVP",
        paramsData,
      });
      console.log("Response:", response.data);
      setData(response.data.result.executiveDetails);
      setTotalPages(response.data.result.totalPages || 0);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const createRowsFromExecutiveManagementData = (data) => {
    return data.map((management, index) => {
      const lastSixDigits = management._id.slice(-4);
      return {
        index: index + 1,
        _id: management._id,
        executiveId: lastSixDigits,
        fullName: management.fullName,
        userRoleType: management.userRoleType,
        mobileNumber: management.mobileNumber,
        email: management.email,
        createdAt: new Date(management.createdAt).toLocaleString("en-IN", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "Asia/Kolkata",
        }),
        status: management.status,
        userMemberType: management.userMemberType,
      };
    });
  };

  const rows = data.length > 0 ? createRowsFromExecutiveManagementData(data) : [];

  const renderCellWithTooltip = (content, limit) => {
    if (content.length > limit) {
      return (
        <Tooltip title={content}>
          <span>{`${content.substring(0, limit)}...`}</span>
        </Tooltip>
      );
    }
    return content;
  };

  return (
    <div>
    {data.length < 1 ? (
      <DataNotFound />
    ) : (
      <TableContainer
        component={Paper}
        sx={{

          borderRadius: "15px",  boxShadow: "0 0 0px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Table aria-label="customized table">
          <TableHead>
            <TableRow sx={{
              "& .MuiTableCell-root": {
                padding: "10px",
              },
            }}>
              <StyledTableCell align="left">S.No.</StyledTableCell>
              <StyledTableCell align="left">Executive ID</StyledTableCell>
              <StyledTableCell align="left">Name</StyledTableCell>
              <StyledTableCell align="left">Type</StyledTableCell>
              <StyledTableCell align="left">Mobile No.</StyledTableCell>
              <StyledTableCell align="left">Email</StyledTableCell>
              <StyledTableCell align="left">
                Created Date & Time
              </StyledTableCell>
              <StyledTableCell align="left">Status</StyledTableCell>
              <StyledTableCell align="left">Added By</StyledTableCell>
              <StyledTableCell align="left" sx={{}}>
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{
            "& .MuiTableCell-root": {
              padding: "11px",
            },
          }}>
            {rows.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="left">{row.index}</StyledTableCell>
                <StyledTableCell align="left">
                  {row.executiveId}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {renderCellWithTooltip(row.fullName, 20)}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.userRoleType}
                </StyledTableCell>

                <StyledTableCell align="left">
                  {row.mobileNumber}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {renderCellWithTooltip(row.email, 20)}
                </StyledTableCell>

                <StyledTableCell align="left">{row.createdAt}</StyledTableCell>
                <StyledTableCell
                  align="left"
                  style={{ color: row.status === "ACTIVE" ? "green" : "red" }}
                >
                  {row.status}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.userMemberType ? row.userMemberType : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  <IoEyeOutline
                    onClick={() => handleViewClick(row._id)}
                    style={{ fontSize: "24px" }}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> )}

      {totalPages > 1 && (
        <Grid container justifyContent="center" mt={3}>
          <Pagination
            color="success"
            count={totalPages}
            page={filter.page}
            onChange={handleChangePage}
          />
        </Grid>
      )}
    </div>
  );
}
