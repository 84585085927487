import React, { useState } from "react";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SidebarData } from "../data/data";
import Profile from "./Profile";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutPopup from "../component/Popup/Logoutpopup";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState(false);

  const active = () => {
    const path = location.pathname;
    const pathSegments = path.split("/");
    return pathSegments[1];
  };

  const handleLogoutClick = () => {
    setIsLogoutPopupOpen(true);
  };

  const handleLogoutConfirm = () => {
    setIsLogoutPopupOpen(false);
  };

  const userData = JSON.parse(sessionStorage.getItem("user"));

  const { fullName, userType, profileImage } = userData || {};

  const filteredSidebarData = SidebarData.filter(
    (item) =>
      !(
        userType === "VP" &&
        (item.name.toLowerCase() === "vp management" ||
          item.name.toLowerCase() === "report management")
      )
  );

  return (
    <Paper
      elevation={0}
      style={{
        padding: "15px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
      }}
    >
      <div style={{ flexShrink: 0 }}>
        <Profile name={fullName} type={userType} profileImage={profileImage} />
      </div>
      <div
        style={{
          flexGrow: 1,
          overflow: "hidden",
          marginTop: "15px",
        }}
      >
        <div
          style={{
            height: "100%",
            overflowY: "auto",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            WebkitOverflowScrolling: "touch",
          }}
        >
          <MenuList>
            {filteredSidebarData.map((item, index) => {
              const menuStyle = {
                backgroundColor:
                  active() === item.name.toLowerCase().replace(" ", "")
                    ? "rgba(0, 176, 80, 1)"
                    : "white",
                color:
                  active() === item.name.toLowerCase().replace(" ", "")
                    ? "white"
                    : "black",
                marginBottom: isMobile ? "15px" : "12px",
                borderRadius: isMobile ? "2.4rem" : "6.25rem",
                display: "flex",
                flexWrap: "wrap",
                height: isMobile ? "60px" : "50px",
                width: "100%",
              };

              const listStyle = {
                fontWeight: 600,
                fontSize: "18px",
                lineHeight: "22.68px",
              };

              return (
                <MenuItem
                  style={menuStyle}
                  key={index}
                  onClick={() => {
                    if (item.name.toLowerCase() === "logout") {
                      handleLogoutClick();
                    } else {
                      navigate(`/${item.name.toLowerCase().replace(" ", "")}`);
                    }
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={
                        active() === item.name.toLowerCase().replace(" ", "")
                          ? item.icon1
                          : item.icon2
                      }
                      alt="s-icons"
                    />
                  </ListItemIcon>
                  <ListItemText style={listStyle}>{item.name}</ListItemText>
                </MenuItem>
              );
            })}
          </MenuList>
        </div>
      </div>
      {isLogoutPopupOpen && (
        <div>
          <LogoutPopup
            isOpen={isLogoutPopupOpen}
            onClose={handleLogoutConfirm}
            name="Logout"
            description="Are you sure you want to logout ?"
            okbtn="Yes"
            cancelbtn="No"
            route="logout"
          />
        </div>
      )}
    </Paper>
  );
};

export default Sidebar;
