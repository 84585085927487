import React from "react";
import { Box } from "@mui/material";
import Sidebar from "../component/Sidebar";
import MainHeader from "../component/MainHeader";
import Footer from "../component/footer/Footer";

const dashboardLayoutStyle = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflow: "hidden",
  },

  headerContent: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },

  titleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "1%",
  },

  sectionTitle: {
    marginLeft: "10px",
  },

  contentSection: {
    display: "flex",
    flex: 1, // Ensures it takes the remaining space
    height: "100%", // Full height to handle scrolling
    overflow: "hidden", // Prevent scrolling outside content
  },

  sectionNameContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  siderbarStyle: {
    width: "20%",
    height: "100%",
    boxShadow: "0 10px 10px -10px #D9D9D9, 10px 0 10px -10px #D9D9D9",
    display: "flex",
    flexDirection: "column",
  },

  contentStyle: {
    width: "80%",
    // padding: "16px 2%",
    margin: "12px",
    boxShadow: "0 0 10px 0 #D9D9D9",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden", // Prevent overflow for the entire content
  },

  sectionNameStyle: {
    textAlign: "end",
  },

  scrollableChildren: {
    flex: 1, // Fills the remaining space
    overflowY: "auto", // Vertical scrolling
    overflowX: "hidden", // Prevent horizontal scrolling
    scrollbarWidth: "none", // Hides scrollbar in Firefox
    "-ms-overflow-style": "none", // Hides scrollbar in IE/Edge

    /* For Webkit-based browsers like Chrome, Safari */
    "&::-webkit-scrollbar": {
      display: "none", // Hides scrollbar
    },
  },

  footerSection: {
    left: "0",
    right: "0",
    bottom: "0",
  },
};

const MainLayout = ({ children }) => {
  return (
    <Box style={dashboardLayoutStyle.mainContainer}>
      <Box style={dashboardLayoutStyle.headerSection}>
        <MainHeader />
      </Box>

      <Box style={dashboardLayoutStyle.contentSection}>
        <Box style={dashboardLayoutStyle.siderbarStyle}>
          <Sidebar />
        </Box>

        <Box style={dashboardLayoutStyle.contentStyle}>
          <Box sx={dashboardLayoutStyle.scrollableChildren}>{children}</Box>
        </Box>
      </Box>

      <Box style={dashboardLayoutStyle.footerSection}>
        <Footer />
      </Box>
    </Box>
  );
};

export default MainLayout;
