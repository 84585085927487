// DatePicker.js

import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const customTheme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "6.25rem",
          height: "3rem",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          lineHeight: "14px",
        },
      },
    },
  },
});

const BasicDatePicker = ({  value, onDateChange,  label = "Select a date"}) => {
  

  

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={customTheme}>
        <DatePicker value={value} onChange={onDateChange} label={label}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default BasicDatePicker;
