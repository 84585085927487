import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import { Box, Typography , Grid , Paper } from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { ApiConfig } from "../../config/ApiConfig";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import arrowBack from "../../assets/arrowBack.svg";

const ViewContent = () => {
  const { id } = useParams(); // Get the ID from the URL params
  const location = useLocation();
  const [data, setData] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios({
          method: "GET",
          url: `${ApiConfig.getAllStaticContentByType}?contentType=${location.state.contentType}`,
        });

        if (response && response?.data?.responseCode === 200) {
          const result = response.data.result;

          // Ensure that contentType is FAQ before proceeding
          if (location.state.contentType === "FAQ") {
            console.log("Fetched FAQ result:", result);

            // If an ID is passed in the URL (params)
            if (id) {
              const specificFAQ = result.find((faq) => faq._id === id);
              if (specificFAQ) {
                console.log("Found specific FAQ:", specificFAQ);
                setData(
                  `<strong>${specificFAQ.question}</strong><br />${specificFAQ.answer}<br /><br />`
                );
              } else {
                console.log("FAQ with the specified ID not found.");
                setData("FAQ not found.");
              }
            } else {
              // If no ID is passed, show all FAQs
              const formattedFAQs = result
                .map(
                  (faq) =>
                    `<strong>${faq.question}</strong><br />${faq.answer}<br /><br />`
                )
                .join("");
              setData(formattedFAQs);
            }
          } else if (result.length > 0) {
            // If not FAQ content type, show the description of the first item
            const description = result[0].description;
            setData(description);
          }
        }
      } catch (error) {
        console.error("An error occurred while fetching the data:", error);
      }
    };

    fetchData();
  }, [id, location.state.contentType]); // Include 'id' and 'contentType' as dependencies

  return (
    <MainLayout>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" padding="1rem">
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            Content Management
          </Typography>
        </Box>
        <Box sx={{ marginLeft: "15px", marginBottom: "15px" }}>
          <Paper elevation={0} style={{ padding: "15px", overflowY: "auto" }}>
          <Box display="flex" alignItems="center" mb={2}>
          <Link to={"/contentmanagement"}>
            <img
              src={arrowBack}
              alt="arrowBack"
              style={{
                width: "2.813rem",
                height: "2.813rem",
                cursor: "pointer",
              }}
            />
          </Link>
          <Typography variant="h5" sx={{ marginLeft: "1rem", fontWeight: 800 }}>
           View {location.state.contentType}
          </Typography>
        </Box>


            <Box sx={{ margin : "25px"}}><CKEditor
            editor={ClassicEditor}
            data={data} // Pre-fill with fetched content
            onReady={(editor) => {
              console.log("Editor is ready to use!", editor);
            }}
            disabled={true} // Disable editing if you only want to view the content
            config={{
              toolbar: [], // Disable the toolbar if you only want to view
            }}
          /></Box>
          </Paper>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default ViewContent;
