import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Typography, Grid, Paper, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import MainLayout from "../../../layout/MainLayout";
import ViewDetail from "../../../component/viewDetail/ViewDetail";
import CustomizedTables from "../../../component/Table/ManagerForVPManagementData";
import CustomizedTable from "../../../component/Table/PropertyManagementData";
import Filter from "../../../component/Filters/Filter";
import PropertyFilter from "../../../component/Filters/PropertyFilter";
import { Link, useNavigate, useParams } from "react-router-dom";
import arrowBack from "../../../assets/arrowBack.svg";
import { getAPIHandler } from "../../../config/service";
import DataNotFound from "../../../assets/DataNotFound.png";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box sx={{ padding: "0px 10px" }}>
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function VPDetails() {
  const [value, setValue] = React.useState(0);
  const { id } = useParams();
  const [data, setData] = React.useState(null);
  const [property, setPropertyData] = React.useState(null);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [category, setCategory] = React.useState("");
  const [constructionStatus, setConstructionStatus] = React.useState("");
  const navigate = useNavigate();

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };
  const handleTypeSelect = (category) => {
    setCategory(category);
  };

  const handleConstructionStatusSelect = (status) => {
    setConstructionStatus(status);
  };

  const getData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "viewProfile",
        paramsData: {
          userId: id,
        },
      });

      console.log("Response:", response.data);
      setData(response.data.result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getPropertyData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "getAllProperty",
        paramsData: {
          memberId: id,
        },
      });

      console.log("Response:", response.data);
      setPropertyData(response.data.result.property);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  React.useEffect(() => {
    getData();
    getPropertyData();
  }, []);

  const vpDetails = [
    { left: "VP ID", value: data ? data._id : null },
    { left: "Full Name", value: data ? data.fullName : null },
    { left: "Mobile Number", value: data ? data.mobileNumber : null },
    { left: "Email Address", value: data ? data.email : null },
    { left: "Created Date & Time", value: data ? new Date(data.createdAt).toLocaleString("en-IN", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "Asia/Kolkata",
    }).replace(",", "") : null },
    { left: "Status", value: data ? data.status : null },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDownloadCSV = () => {
    const downloadFunction = document.getElementById("vp-tablesdata-download");
    if (downloadFunction) {
      downloadFunction.click();
    }
  };

  let downloadCSVFunction = null;

  return (
    <MainLayout>
      <Grid container alignItems="center" padding={2}>
        <Typography variant="h4" sx={{ fontWeight: 700 }}>
          VP Management
        </Typography>
      </Grid>

      <Grid sx={{ overflowY: "auto" }} paddingX={2}>
        <Paper elevation={0}>
          <Grid container spacing={2} padding={1}>
            <Grid item xs={2}>
              <Box display="flex" alignItems="center">
                <img
                  src={arrowBack}
                  alt="Back"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                />
              </Box>
            </Grid>

            <Grid item xs={8}>
              <Box Box display="flex" justifyContent="center">
                <Tabs value={value} onChange={handleChange} centered>
                  {["General Information", "Property List", "Manager List"].map(
                    (label, index) => (
                      <Tab
                        key={index}
                        label={
                          <Typography
                            sx={{
                              textTransform: "none",
                              color:
                                value === index
                                  ? "rgba(0, 176, 80, 1)"
                                  : "#333",
                            }}
                          >
                            {label}
                          </Typography>
                        }
                      />
                    )
                  )}
                </Tabs>
              </Box>
            </Grid>
          </Grid>

          <Divider sx={{ margin: 1 }} />

          <CustomTabPanel value={value} index={0}>
            <Grid container justifyContent="center">
              <ViewDetail type="executive" details={vpDetails} />
            </Grid>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <Grid>
              <Box sx={{ marginBottom: "15px" }}>
                <PropertyFilter
                  searchPlaceholder={"Search by property name..."}
                  onSearch={handleSearch}
                  onFromDateChange={handleFromDateChange}
                  onToDateChange={handleToDateChange}
                  onConstructionStatusChange={handleConstructionStatusSelect}
                  onCategoryChange={handleTypeSelect}
                />
              </Box>
              <CustomizedTable
                memberId={id}
                searchQuery={searchQuery}
                fromDate={fromDate}
                toDate={toDate}
                category={category}
                constructionStatus={constructionStatus}
                setDownloadCSVFunction={(func) => {
                  downloadCSVFunction = func;
                }}
                showEditAndBlockIcons={false}
              />
            </Grid>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={2}>
            <Box sx={{ marginBottom: "15px" }}>
              <Filter
                searchPlaceholder={"Search by manager name..."}
                onSearch={handleSearch}
                onFromDateChange={handleFromDateChange}
                onToDateChange={handleToDateChange}
              />
            </Box>
            <CustomizedTables
              vpId={id}
              searchQuery={searchQuery}
              fromDate={fromDate}
              toDate={toDate}
            />
          </CustomTabPanel>
        </Paper>
      </Grid>
    </MainLayout>
  );
}
