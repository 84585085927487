import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ height, width, backgroundColor, borderRadius, border, onClick, type, disabled, children }) => {
  const buttonStyle = {
    height: height,
    width: width,
    backgroundColor: backgroundColor,
    borderRadius: borderRadius,
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
    border: border,
    cursor: disabled ? 'not-allowed' : 'pointer',
    padding: '10px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#fff',
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <button style={buttonStyle} type={type} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

Button.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  borderRadius: PropTypes.string.isRequired,
  border: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node, // Accept children prop
};

export default Button;
