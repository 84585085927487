import React from "react";
import {
  Container,
  Box,
  Typography,
  AppBar,
  Toolbar,
  CssBaseline,
  useMediaQuery,
} from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import logo from "../assets/Logo.png";
import sampleImage from "../assets/Frame.svg";

const Header = styled(AppBar)(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.7)",
  position: "sticky",
  boxShadow: "none",
}));

const Footer = styled(Box)(({ theme }) => ({
  background: "rgba(0, 176, 80, 0.05)",
  padding: theme.spacing(2),
  textAlign: "center",
}));

const Content = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start", // Align items to the start to reduce space between
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column", // Stack items vertically on mobile
    alignItems: "center", // Center items on mobile
  },
}));

const LeftContent = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  paddingRight: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    paddingRight: 0,
    justifyContent: "center",
    width: "100%",
  },
}));

const RightContent = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    marginBottom: theme.spacing(2),
    width: "100%",
  },
}));

const AuthLayout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      scrollBarWidth="none"
    >
      <CssBaseline />
      <Header>
        <Toolbar>
          <Typography variant="h6" color="inherit" style={{ flexGrow: 1 }}>
            <img
              src={logo}
              alt="banking_logo"
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            />
          </Typography>
        </Toolbar>
      </Header>
      <Content>
        <RightContent>
          <img
            src={sampleImage}
            alt="description"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </RightContent>
        <Container maxWidth="sm" disableGutters>
          <LeftContent>{children}</LeftContent>
        </Container>
      </Content>
      <Footer>
        <Typography variant="body2" color="rgb(0, 176, 80)">
          © {new Date().getFullYear()} Admin panel Developed By Mobiloitte
          Technologies
        </Typography>
      </Footer>
    </Box>
  );
};

export default AuthLayout;
