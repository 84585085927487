import React from "react";
import { Routes, Route } from "react-router-dom";
import VPManagement from "../pages/VPManagement/VPManagement";
import PropertyManagement from "../pages/PropertyManagement/PropertyManagement";
import DeveloperManagement from "../pages/DeveloperManagement/DeveloperManagement";
import ExecutiveManagement from "../pages/ExecutiveManagement/ExecutiveManagement";
import ManagerManagement from "../pages/ManagerManagement/ManagerManagement";
import Dashboard from "../pages/Dashboard/Dashboard";
import Login from "../pages/Login/Login";
import MyAccount from "../pages/Account/MyAccount";
import ReportManagement from "../pages/ReportManagement/ReportManagement";
import BroadcastManagement from "../pages/BroadcastManagement/BroadcastManagement";
import ViewDeveloper from "../pages/DeveloperManagement/ViewDeveloper/ViewDeveloper";
import AddDeveloper from "../pages/DeveloperManagement/AddDeveloper/AddDeveloper";
import EditDeveloper from "../pages/DeveloperManagement/EditDeveloper/EditDeveloper";
import VPDetails from "../pages/VPManagement/VPDetails/VPDetails";
import AddProperty from "../pages/PropertyManagement/AddProperty/AddProperty";
import BarGraph from "../component/bar_graph/BarGraph";
import EditProfile from "../pages/Account/EditProfile";
import ChangePassword from "../pages/Account/ChangePassword";
import AddManager from "../pages/ManagerManagement/AddManager/AddManager";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import OTPVerification from "../pages/OTPVerification/OTPVerification";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import EditProperty from "../pages/PropertyManagement/EditProperty/EditProperty";
import LeadsDetail from "../pages/LeadsManagement/LeadsDetail";
import LeadDetailGenerated from "../pages/LeadsManagement/LeadDetailGenerated";
import LeadDetailBooked from "../pages/LeadsManagement/LeadDetailBooked";
import ViewProperty from "../pages/PropertyManagement/ViewProperty/ViewProperty";
import ManagerDetail from "../pages/ManagerManagement/ManagerDetail";
import ExecutiveDetail from "../pages/ExecutiveManagement/ExecutiveDetail";
import AddExecutive from "../pages/ExecutiveManagement/AddExecutive/AddExecutive";
import EditManager from "../pages/ManagerManagement/EditManager/EditManager";
import AddBroadcast from "../pages/BroadcastManagement/AddBroadcast/AddBroadcast";
import AddVP from "../pages/VPManagement/AddVP/AddVP";
import EditVP from "../pages/VPManagement/UpdateVp/UpdateVP";
import EditExecutive from "../pages/ExecutiveManagement/UpdateExecutive/UpdateExecutive";
import AddProject from "../pages/ExecutiveManagement/AddProject/AddProject";
import EditBroadcast from "../pages/BroadcastManagement/EditBroadcast/EditBroadcast";
import ViewBroadcast from "../pages/BroadcastManagement/ViewBroadcast/ViewBroadcast";
import AuthGaurd from "../component/AuthGaurd";
import ViewAssignedProperty from "../pages/PropertyManagement/ViewAssignedProperty/ViewAssignedProperty";
import LeadManagement from "../pages/LeadsManagement/LeadManagement";
import StaticContentManagement from "../pages/StaticContentManagement/StaticContentManagement";
import TokenManagement from "../pages/TokenManagement/TokenManagement";
import ViewContent from "../pages/StaticContentManagement/ViewContent";
import CreateContent from "../pages/StaticContentManagement/CreateContent";
import EditContent from "../pages/StaticContentManagement/EditContent";
import UrlManagement from "../pages/UrlManagement/UrlManagement";
import AddExternalUrl from "../pages/UrlManagement/AddExternalUrl/AddExternalUrl";
import EditExternalUrl from "../pages/UrlManagement/EditExternalUrl/EditExternalUrl";
import ViewExternalUrl from "../pages/UrlManagement/ViewExternalUrl/ViewExternalUrl";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/otpVerification" element={<OTPVerification />} />
      <Route path="/resetpassword" element={<ResetPassword />} />

      <Route element={<AuthGaurd />}>
        <Route path="/dashboard" element={<Dashboard />} />

        <Route path="/developermanagement" element={<DeveloperManagement />} />
        <Route
          path="/developermanagement/viewdeveloper/:id"
          element={<ViewDeveloper />}
        />
        <Route
          path="/developermanagement/adddeveloper"
          element={<AddDeveloper />}
        />
        <Route
          path="/developermanagement/editdeveloper/:id"
          element={<EditDeveloper />}
        />

        <Route path="/propertymanagement" element={<PropertyManagement />} />
        <Route
          path="/propertymanagement/addproperty"
          element={<AddProperty />}
        />
        <Route
          path="/propertymanagement/editproperty/:propertyId"
          element={<EditProperty />}
        />
        <Route
          path="/propertymanagement/viewproperty/:id"
          element={<ViewProperty />}
        />

        <Route
          path="/propertymanagement/viewassignedproperty/:id"
          element={<ViewAssignedProperty />}
        />

        <Route path="/executivemanagement" element={<ExecutiveManagement />} />
        <Route
          path="/executivemanagement/executivedetail/:id"
          element={<ExecutiveDetail />}
        />
        <Route
          path="/executivemanagement/addexecutive"
          element={<AddExecutive />}
        />
        <Route
          path="/executivemanagement/editexecutive/:id"
          element={<EditExecutive />}
        />
        <Route path="/addproject" element={<AddProject />} />

        <Route path="/managermanagement" element={<ManagerManagement />} />
        <Route
          path="/managermanagement/managerdetail/:id"
          element={<ManagerDetail />}
        />
        <Route path="/managermanagement/addmanager" element={<AddManager />} />
        <Route
          path="/managermanagement/editmanager/:id"
          element={<EditManager />}
        />

        <Route path="/vpmanagement" element={<VPManagement />} />
        <Route path="/vpmanagement/vpdetails/:id" element={<VPDetails />} />
        <Route path="/vpmanagement/addvp" element={<AddVP />} />
        <Route path="/vpmanagement/editvp/:id" element={<EditVP />} />

        <Route path="/leadsmanagement" element={<LeadManagement />} />
        <Route
          path="/leadsmanagement/leadsdetail/:id"
          element={<LeadsDetail />}
        />
        <Route
          path="/leadsmanagement/leadsdetailgenerated/:id"
          element={<LeadDetailGenerated />}
        />
        <Route
          path="/leadsmanagement/leadsdetailbooked/:id"
          element={<LeadDetailBooked />}
        />

        <Route path="/broadcastmanagement" element={<BroadcastManagement />} />
        <Route
          path="/broadcastmanagement/addbroadcast"
          element={<AddBroadcast />}
        />
        <Route
          path="/broadcastmanagement/editbroadcast/:id"
          element={<EditBroadcast />}
        />
        <Route
          path="/broadcastmanagement/viewbroadcast/:id"
          element={<ViewBroadcast />}
        />

        <Route path="/reportmanagement" element={<ReportManagement />} />

        <Route path="/contentmanagement" element={<StaticContentManagement />} />
        <Route path="/contentmanagement/createcontent" element={<CreateContent />} />
        <Route path="/contentmanagement/viewcontent/:id" element={<ViewContent />} />
        <Route path="/contentmanagement/editcontent/:id" element={<EditContent />} />


        <Route path="/tokenmanagement" element={<TokenManagement />} />

        <Route path="/urlmanagement" element={<UrlManagement />} />
        <Route
          path="/urlmanagement/addexternalurl"
          element={<AddExternalUrl />}
        />
        <Route
          path="/urlmanagement/editexternalurl"
          element={<EditExternalUrl />}
        />
        <Route
          path="/urlmanagement/viewexternalurl"
          element={<ViewExternalUrl />}
        />

        <Route path="/myaccount" element={<MyAccount />} />
        <Route path="/myaccount/editprofile/:id" element={<EditProfile />} />
        <Route
          path="/myaccount/changepassword/:id"
          element={<ChangePassword />}
        />

        <Route path="/BarGraph" element={<BarGraph />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
