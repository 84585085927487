import React from "react";
import { Box } from "@mui/material";
import "./ViewDetail.css";

const ViewDetail = ({ details, type }) => {
  return (
    <Box className="detail-container">
      {details && details.length > 0 ? (
        <Box className={`${type}-details`}>
          {details.map((detail, index) => (
            <Box
              key={index}
              className="detail-row"
            >
              <Box className="detail-left">
                {detail.left}
              </Box>
              <Box className="detail-colon">
                :
              </Box>
              <Box className="detail-value">
                {detail.value}
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Box className="error-message">Error !!!!!</Box>
      )}
    </Box>
  );
};

export default ViewDetail;
