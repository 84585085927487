import React, { useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid } from "@mui/material";
import ButtonComponent from "../Button";
import { toast } from "react-hot-toast";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { putAPIHandler } from "../../config/service";

export default function LogoutPopup({
  isOpen,
  onClose,
  name,
  description,
  okbtn,
  cancelbtn,
  userId,
  propertyId,
  developerId,
  broadcastId,
  route,
  data,
  setData,
  type,
}) {
  const handleClose = () => {
    onClose();
  };

  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleNavigate = () => {
    onClose();
    if (route === "logout") {
      logout();
      navigate("/", { replace: true });
    }
  };

  const handleCheckStatus = async (idToUpdate) => {
    try {
      if (!idToUpdate) {
        console.error("Invalid ID for updating status");
        return;
      }

      const item = data.find((item) => item._id === idToUpdate);
      let newStatus;

      if (type === "broadcast") {
        newStatus = "SENDED";
      } else {
        newStatus = item.status === "ACTIVE" ? "BLOCKED" : "ACTIVE";
      }

      let endpoint = "";
      switch (type) {
        case "user":
          endpoint = "updateProfile";
          break;
        case "property":
          endpoint = "updateProperty";
          break;
        case "developer":
          endpoint = "updateDeveloper";
          break;
        case "broadcast":
          endpoint = "updateBroadcast";
          break;
        default:
          console.error("Invalid type for updating status");
          return;
      }

      const response = await putAPIHandler({
        endPoint: endpoint,
        dataToSend: {
          [`${type}Id`]: idToUpdate,
          status: newStatus,
        },
      });

      if (response && response.data) {
        const updatedData = data.map((item) =>
          item._id === idToUpdate ? { ...item, status: newStatus } : item
        );
        setData(updatedData);
        if (response && response.data.responseCode === 200) {
          toast.success("Status updated successfully");
        }

        console.log("Status updated successfully:", response.data);
      } else {
        console.error("Invalid API response:", response);
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Error updating status");
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "1.34rem",
        },
      }}
    >
      <ClearIcon
        fontSize="small"
        style={{
          position: "absolute",
          cursor: "pointer",
          color: "grey",
          top: 11,
          right: 10,
        }}
        onClick={handleClose}
      />

      <DialogTitle style={{ textAlign: "center" }}>{name}</DialogTitle>

      <Grid
        style={{
          borderBottom: "0.1rem solid lightgrey",
        }}
      ></Grid>

      <DialogContent>
        <DialogContentText style={{ textAlign: "center" }}>
          {description}
        </DialogContentText>
      </DialogContent>

      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <ButtonComponent
          name={cancelbtn}
          backgroundColor={"rgba(225, 225, 225, 1)"}
          color={"Black"}
          borderRadius={"100px"}
          width={"159px"}
          onClick={handleClose}
        />
        <ButtonComponent
          name={okbtn}
          borderRadius={"100px"}
          width={"159px"}
          onClick={() => {
            handleNavigate();
            if (type === "user") {
              handleCheckStatus(userId);
            } else if (type === "property") {
              handleCheckStatus(propertyId);
            } else if (type === "developer") {
              handleCheckStatus(developerId);
            } else if (type === "broadcast") {
              handleCheckStatus(broadcastId);
            }
          }}
        />

      </DialogActions>
    </Dialog>
  );
}
