import React, { useEffect, useRef } from 'react';
import Swiper from 'swiper';
import 'swiper/css/bundle';
import './MovieSlider.css'; 

function MovieSlider({images}) {
  const swiperRef = useRef(null);

  useEffect(() => {

  
    swiperRef.current = new Swiper('.swiper', {
      loop: true,
      slidesPerView: 1,
      slidesPerGroup: 1,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        bulletClass: 'swiper-pagination-bullet',
        bulletActiveClass: 'swiper-pagination-bullet-active',
        renderBullet: function (index, className) {
          return `<span class="${className}"></span>`;
        },
      },
    });


    return () => {
      if (swiperRef.current && swiperRef.current.destroy) {
        swiperRef.current.destroy(); 
      }
    };
  }, []);

  return (
    <div className="image-slider-container">
      <div className="swiper">
        <div className="swiper-wrapper">
          {images.map((images, index) => (
            <div className="swiper-slide" key={index}>
              <img src={images} alt={`Slide ${index}`} />
            </div>
          ))}
        </div>
        <div className="swiper-pagination"></div>
      </div>
    </div>
  );
}

export default MovieSlider;
