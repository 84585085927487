import React from "react";
import datanotfound from "../assets/DataNotFound.png";
import { Box } from "@mui/material";
// const useStyles = makeStyles((theme) => ({
//   imgBox: {
//     maxWidth: "300px",
//     width: "100%",
//     [theme.breakpoints.down("xs")]: {
//       maxWidth: "200px",
//       width: "100%",
//     },
//   },
//   mainBox: {
//     "& h3": {
//       marginTop: "6px",
//       color: "rgba(0, 0, 0, 0.87)",
//     },
//   },
// }));
export default function DataNotFound({ text }) {
  return (
    <Box mt={6} mb={4}>
      <Box align="center">
        {/* {/ <Typography variant="h3">{text ? text : "No data found"}</Typography> /} */}
        <img src={datanotfound} alt="nodata" />
      </Box>
    </Box>
  );
}
